// import {useState} from 'react'

import "./footer.scss"

import logo from "../../images/docubric-logo-white.svg";
import facebookIcon from "../../images/social-icons/facebook-icon-01.svg";
import instagramIcon from "../../images/social-icons/instagram-icon-01.svg";
import linkedinIcon from "../../images/social-icons/linkedin-icon.svg";

import {Link} from "react-router-dom";

// const footer_accordion = [
//     {
//         "title": "Contact Information",
//         items: [
//             <p>Phone: <a href="tel:+880-1303-546803">+880-1303-546803</a></p>,
//             <p>Email: <a href="mailto:hello@docubric.com">hello@docubric.com</a></p>
//         ]
//     },
//     {
//         "title": "Career",
//         items: [
//             <Link rel="canonical" to="/careers" target="_blank" rel="noopener noreferrer"><p>Join our team</p></Link>
//         ]
//     },
//     {
//         "title": "New Business",
//         items: [
//             <Link rel="canonical" to="/partnership" target="_blank" rel="noopener noreferrer"><p>Partnership with us</p></Link>
//         ]
//     }
// ] 

const links = {
    "facebook": "https://www.facebook.com/Docubric/",
    "linkedin": "https://www.linkedin.com/company/docubric/",
    "instagram": "https://www.instagram.com/docubric/"
}

const Footer = () => {
//   const [selected, setSeleced] = useState(null);

//   const toggle = (i) => {
//       if (i === selected) {
//         return setSeleced(null);
//       }
    
//     setSeleced(i);
//   }

    return (
    <div className="footer">
        <div className="footer-content">
            <div className="footer--links">
                <img id="footer-logo" src={logo} alt="logo" width="150px"/>
                <h2>Follow us</h2>
                <div className="footer--links-icons" >
                    {/* # social links */}
                    <a href={links.facebook} target="_blank" rel="noopener noreferrer"><img src={facebookIcon} alt="facebook" width="35px"/></a> 
                    <a href={links.instagram} target="_blank" rel="noopener noreferrer"><img src={instagramIcon} alt="instagramIcon" width="35px"/></a>
                    <a href={links.linkedin} target="_blank" rel="noopener noreferrer"><img src={linkedinIcon} alt="linkedin" width="35px"/></a>
                </div>
            </div>

            <div className="footer--sitemap">
                <h2>Sitemap</h2>
                <div>
                    <ul>
                        <Link rel="canonical" to="/"><li>Home</li></Link>
                        <Link rel="canonical" to="/about"><li>About</li></Link>
                        <Link rel="canonical" to="/services"><li>Services</li></Link>
                        <Link rel="canonical" to="/blog"><li>Blog</li></Link>
                    </ul>
                </div>
            </div>

            <div className="footer--contact-info">
                <h2>Contact Info</h2>
                <div>
                    {/* mailto:hello@docubric.com  +880-1303-546803 */}
                    <p>Phone: <a href="tel:+880-1303-546803">+880-1303-546803</a></p>
                    <p>Email: <a href="mailto:hello@docubric.com">hello@docubric.com</a></p>
                    <h3>
                    {/* +880-1303-546803 */}
                    Live Chat on<br /> <a href="https://api.whatsapp.com/send?phone=8801303546803&text=Hello%20👋" target="_blank" rel="noopener noreferrer"><span>WhatsApp</span></a> 
                    </h3>
                </div>
            </div>

            <div className="footer--career">
                <h2>Career</h2>
                    <Link to="/careers" target="_blank" rel="noopener noreferrer canonical"><p>Join Our Team</p></Link>
                <h2>New Business</h2>
                    <Link to="/partnership" target="_blank" rel="noopener noreferrer canonical"><p>Partnership Opportunities</p></Link>
            </div>

        </div>


        <div className="footer-content-mobile">
            <div className="footer-menu-accordion">
                {/*{ footer_accordion.map((item, index) => (
                    <div className="footer-menu-accordion-container" key={index} onClick={() => toggle(index)} >
                        <div className="footer-menu-accordion--head">
                            <h1>{item.title}</h1>
                            <div className="footer-menu-accordion-img">
                                <svg onClick={() => toggle(index)} xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="12" height="12" style={{transform: selected === index ? "rotateX(180deg)" : "rotateX(0)"}} ><path d="M12,17.17a5,5,0,0,1-3.54-1.46L.29,7.54A1,1,0,0,1,1.71,6.12l8.17,8.17a3,3,0,0,0,4.24,0l8.17-8.17a1,1,0,1,1,1.42,1.42l-8.17,8.17A5,5,0,0,1,12,17.17Z"/></svg>
                            </div>
                        </div>
                        <div className="footer-menu-accordion-item-content" style={{display: selected === index ? "flex" : "none"}}>
                            {item.items.map((item_inner, index) => (
                                <div key={index}>
                                    {item_inner}
                                </div>
                            ))}
                        </div>
                        <div className="footer-mobile-menu--border"></div>
                    </div>
                ))} */}

                    <div className="footer-menu-accordion-container" >
                        <div className="footer-menu-accordion--head">
                            <h1>Contact Information</h1>
                        </div>
                        <div className="footer-menu-accordion-item-content">
                            <p>Phone: <a href="tel:+880-1303-546803">+880-1303-546803</a></p>
                            <p>Email: <a href="mailto:hello@docubric.com">hello@docubric.com</a></p>
                        </div>
                        <div className="footer-mobile-menu--border"></div>
                    </div>

                    <div className="footer-menu-accordion-container" >
                        <div className="footer-menu-accordion--head">
                            <Link to="/careers" target="_blank" rel="noopener noreferrer canonical"><h1>Join Our Team</h1></Link>
                        </div>
                        <div className="footer-mobile-menu--border"></div>
                    </div>

                    <div className="footer-menu-accordion-container" >
                        <div className="footer-menu-accordion--head">
                            <Link to="/partnership" target="_blank" rel="noopener noreferrer canonical"><h1>Partnership Opportunities</h1></Link>
                        </div>
                        <div className="footer-mobile-menu--border"></div>
                    </div>

                



            </div>
            <div className="footer--links">
                <h2>Follow us</h2>
                <div className="footer--links-icons" >
                    <a href={links.facebook} target="_blank" rel="noopener noreferrer"><img src={facebookIcon} alt="facebook" width="35px"/></a> 
                    <a href={links.instagram} target="_blank" rel="noopener noreferrer"><img src={instagramIcon} alt="instagramIcon" width="35px"/></a>
                    <a href={links.linkedin} target="_blank" rel="noopener noreferrer"><img src={linkedinIcon} alt="linkedin" width="35px"/></a>
                </div>
            </div>
        </div>


        <div className="footer--copyright">
            <h3>Copyright © 2022 - 2023 Docubric. All rights reserved.</h3>
            <div>
                <Link rel="canonical" to="/privacy-policy"><p>Privacy Policy</p></Link>
                {/* <Link rel="canonical" to="/"><p>Terms and Conditions</p></Link> */}
            </div>
        </div>

    </div>
    )
}

export default Footer