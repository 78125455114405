import {useEffect} from 'react'

import Section1 from "./Section-1/Sction1";


import ReactGA from 'react-ga';

const Careers = (props) => {
  useEffect(() => {
      ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])


  useEffect(() => {
    document.title = props.title 
    document.querySelector('meta[name="description"]').setAttribute('content', props.description)
  }, [props.title, props.description]);
  
  return (
    <>
        <Section1 />
    </>
  )
}

export default Careers