import {useEffect} from 'react'

import { 
    Navbar, 
    CustomBanner, 
    BottomBanner,
    Footer,
    // NewsLetter,
    ChatBubble
} from "../../components";

import {
  Section2,
  Section3,
  Section5
} from "./about-components";

import aboutBanner from "../../images/banners/about-page-banner.png";

import ReactGA from 'react-ga';



const About = (props) => {

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  useEffect(() => {
    document.title = props.title 
    document.querySelector('meta[name="description"]').setAttribute('content', props.description)
  }, [props.title, props.description]);

  return (
    <>
        <Navbar />
        <CustomBanner bgImg={aboutBanner} headText="A team of experts creating business documents for better communication" />
        <ChatBubble />
        <Section2 />
        <Section3 />
        <Section5 />
        {/* <NewsLetter /> */}
        <BottomBanner isAbout={true}/>
        <Footer />
    </>

  )
}

export default About