import React from 'react'


import "./contactsection2.scss"

import sideBannerContact from "../../../../images/contact-page/Img-handshake.jpg";

const ContactSection2 = () => {
  return (
    <div className="contact-section2">
      
      <div className="contact-section2--container">
          <div className="contact-section2--container-text">
            <div className="contact-section2--head">
              <h1>Let’s do <span>business together</span> </h1>
            </div>
                <h2>Are you a business owner or individual in search of professional document writing and design services? Do you need a reliable document writing company you can rely on? Then <strong>Docubric</strong> is just what you need.</h2>
                <h2>We will provide you with the best presentations, white papers, and case studies to help you achieve your business objectives as efficiently as possible. Contact us today and get to enjoy quality deliverables, great customer service, and all at affordable rates!</h2>
            </div>
          <div className="contact-section2--container-img">
            <img src={sideBannerContact} alt="about-page-banner" width="500px"/>
          </div>
      </div>

      <div id="contact-form"></div>

    </div>
  )
}

export default ContactSection2