import {React, useEffect, useState} from "react";
import {Link} from "react-router-dom";

import logo from "../../images/logo.svg";
import hamburgarMenuIcon from "../../images/hamburger-menu.svg";
import menuCloseIcon from "../../images/hamburger-close.svg";
import "./navbar.scss";


export const Navbar = () => {
  const [posY, setPosY] = useState(window.scrollY);
  const [menuRight, setMenuRight] = useState("-100%");
 
  useEffect(() => {
    window.addEventListener("scroll", (e) => handleAnim(e));
  
    window.removeEventListener("scroll", (e) => handleAnim(e));

    
  }, [posY]);

  window.addEventListener("resize", (e) => {
    if (window.innerWidth > 1085) {
      handleMobileNavClose();
    }
  });

  const handleMobileNavOpen = () => {
      let navbar = document.querySelector(".navbar-mobile--container");
      navbar.classList.remove("navbar-mobile-close");
      navbar.classList.add("pad-top-40");
      navbar.classList.add("navbar-mobile-open");
  }

  const handleMobileNavClose = () => {
    let navbar = document.querySelector(".navbar-mobile--container");
    navbar.classList.remove("navbar-mobile-open");
    navbar.classList.remove("pad-top-40");
    navbar.classList.add("navbar-mobile-close");
    
  }
  


  const handleAnim = (e) => {
    const navbar = document.querySelector("#navbar-handle");
    if (window.scrollY > 0) {
      navbar.classList.add("sticky");

    } else{
      const navbar = document.querySelector("#navbar-handle");
      navbar.classList.remove("sticky");
    }

};


  return (
    <>
      <nav className="navbar" id="navbar-handle">
        <div className="navbar__logo">
          <a href="/"><img src={logo} alt="logo" width="150px" id="main-logo" /></a>
        </div>
        <div className="navbar__menu">
          <div className="navbar__menu_hamburger-menu">

            <img src={hamburgarMenuIcon} alt="hamburgar-menu" width="30px" onClick={() => handleMobileNavOpen()} />
          
          </div>
          <ul className="navbar__menu-list">
            <li className="navbar__menu-item">
              <Link to="/about" className="navbar__menu-link nav-l">about</Link>
            </li>
            <li className="navbar__menu-item">
            <Link to="/services" className="navbar__menu-link nav-l">services</Link>
            </li>
            <li className="navbar__menu-item">
            <Link to="/blog" className="navbar__menu-link nav-l">blog</Link>
            </li>
            <li className="navbar__menu-item nav-contact">
              <Link to="/contact" className="rounded-button">contact</Link>
            </li>
          </ul>
        </div>
      </nav>

    <div className="navbar-mobile" >
        <div className="navbar-mobile--container" style={{right: menuRight}}>
          <div className="navbar-mobile--container-img">
            <img src={menuCloseIcon} alt="Close Icon" width="30px" onClick={() => handleMobileNavClose()} />
          </div>
          <ul className="navbar-mobile-menu">
            <li className="navbar-mobile-menu-item">
                <Link to="/" className="navbar-mobile-menu--link">home</Link>
              </li>
              <div className="navbar-mobile-menu--border"></div>
              <li className="navbar-mobile-menu-item">
                <Link to="/about" className="navbar-mobile-menu--link">about</Link>
              </li>
              <div className="navbar-mobile-menu--border"></div>
              <li className="navbar-mobile-menu-item">
                <Link to="/services" className="navbar-mobile-menu--link">services</Link>
              </li>
              <div className="navbar-mobile-menu--border"></div>
              <li className="navbar-mobile-menu-item">
                <Link to="/blog" className="navbar-mobile-menu--link">blog</Link>
              </li>
              <div className="navbar-mobile-menu--border"></div>
              <li className="navbar-mobile-menu-item">
                <Link to="/contact" className="navbar-mobile-menu--link">contact</Link>
              </li>
            </ul>
            <h2 className="navbar-mobile-contact">Have a project in mind? Email us at <a href="mailto:hello@docubric.com">hello@docubric.com</a></h2>
        </div>
       
    </div>
    </>
   
  );
};


export default Navbar;