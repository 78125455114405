import {useEffect}  from "react";

import "./App.scss";

import { 
  Navbar, 
  Banner, 
  Section2, 
  Section3,
  Section4,
  Section5,
  Section6,
  BottomBanner,
  ChatBubble,
  Footer
} from "./components"; 


// whats app link: https://api.whatsapp.com/send?phone=15719323016&text=Hello%20%F0%9F%91%8B


import ReactGA from 'react-ga';


function App(props) {
  useEffect(() => {
      ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])


  useEffect(() => {
    document.title = props.title
    document.querySelector('meta[name="description"]').setAttribute('content', props.description)
  }
  , [props.title, props.description]);

  return (
    <div>
      <Navbar />
      <Banner />
      <ChatBubble />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      {/* <NewsLetter /> */}
      <BottomBanner />
      <Footer />
    </div>
  );
}

export default App;
