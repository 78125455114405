import React, {useState} from 'react'

import "./chatbubble.scss"

import chatBubbleImg from "../../images/chat-bubble/whatsapp-icon-floating-01.svg"
import profilePic from "../../images/chat-bubble/profile-pic-chat.jpg"
import closeIcon from "../../images/chat-bubble/close-icon-bubble.png"

const ChatBubble = () => {

    const [contentScale, setContentScale] = useState(false)

    return (
        <div className="chat-bubble">
            {/* // TODO: TURN IT INTO ANIMITON SYSTEM */ } 
            <div className="chat-bubble--content" style={{
                opacity: contentScale ? "1" : "0",
                bottom: contentScale ? "175px" : "150px",
                transform: contentScale ? "scale(1)" : "scale(.95)",
                display: contentScale ? "block" : "none" }}>
                 <div className="chat-bubble--content--top">
                    <p>WhatsApp</p>
                    
                    <img src={closeIcon} alt="close-icon" width="10px"
                    onClick={() => setContentScale(!contentScale)}/>
                    
                </div>
                <div className="chat-bubble--content--header">
                    <div className="chat-bubble--content-img">
                        <img id="profile-img" src={profilePic} alt="chat bubble icon" width="50px"/>
                    </div>

                    <div className="chat-bubble--content-text">
                        <p>Hi there! Please let me know if you have any questions.</p>
                    </div>
                </div>
                <div className="chat-bubble--button">
                    <a href="https://api.whatsapp.com/send?phone=8801303546803&text=Hello%20👋" target="_blank" rel="noopener noreferrer"><button>Continue to chat</button></a>
                </div>
                
            </div>
            <div className="chat-bubble--container" >
                <img src={chatBubbleImg} alt="chatBubbleImg" width="80px" onClick={() => setContentScale(!contentScale)}/>
            </div>
        </div>
    )
}

export default ChatBubble