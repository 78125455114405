import React from 'react'

import branchesIcon from "../../images/branches-01.svg";
import moneyBagIcon from "../../images/money-bag-01.svg";
import growthBarIcon from "../../images/growth-bars-01.svg";
import reSearchIcon from "../../images/research-icon-01.svg";

import "./section3.scss"

const Section3 = () => {
  return (
    <div className="section3">
        <div className="section3--header">
            <h1>The ultimate document solutions</h1>
        </div>

        <div className="section3-items">
          <div className="section3--card">
              <div className="section3--card-head">
                      <h1>Internal Alignment</h1>
                      <img src={branchesIcon} alt="icon-5" width="40px"/>
                </div>
                <h4>While a business/organization may have different departments within it, it has to ensure harmony in both function & principle across all those various departments.</h4>
              </div>

            <div className="section3--card">
                <div className="section3--card-head">
                    <h1>Help Fundraising</h1>
                        <img src={moneyBagIcon} alt="icon-5" width="40px"/>
                    </div>
                <h4>An individual’s or organization’s fundraising success is to a large extent determined by how well their goal and purpose for fundraising is articulated to investors.</h4>
            </div>
        </div>

        <div className="section3-items">
          <div className="section3--card">
              <div className="section3--card-head">
                      <h1>Growth and Revenue</h1>
                      <img src={growthBarIcon} alt="icon-5" width="40px"/>
                  </div>
                  <h4>The importance of quality documents cannot be divorced from the growth of a business or organization.</h4>
              </div>

            <div className="section3--card">
                <div className="section3--card-head">
                    <h1>Scaling Business</h1>
                        <img src={reSearchIcon} alt="icon-5" width="40px"/>
                    </div>
                <h4>At the end of the day, a business’s and organization’s goal is to increase its revenue and scale up its operations.</h4>
            </div>
        </div>

        

    </div>
  )
}

export default Section3