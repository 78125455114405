import React from 'react'


import "./ppsection1.scss"

const PPSection1 = () => {
  return (
    <div className="privacy-policy">
        <h1>Privacy Policy</h1>

        <div className="privacy-policy--short-version">
            <div>
                <h3>Short version</h3>
                <p>Docubric will not do anything abnormal with your data. We will not sell or share your name, email address, phone number or any other personal data to any third-parties for the purpose of marketing. We only use the information you share with us to contact you.</p>
                <p>We also use cookies and analytics on our site to improve the site experience and keep the information on our site relevant.</p>
                <p>Any information, including files, that you share with us via our website will be treated as confidential and not shared with any third-parties. We delete all files if we do not engage in further business.</p>
            </div>
        </div>

        <div className="privacy-policy--border"></div>

        <div className="privacy-policy--long-version">
            <div>
                <h3>Long version</h3>
                <p>We are committed to safeguarding the privacy of our website visitors; this policy sets out how we will treat your personal information.</p>
            </div>

            <div>
                <h2>What information do we collect?</h2>
                <p>We may collect, store and use the following kinds of personal data: </p>
                <p>(a) information about your computer and about your visits to and use of this website (including your IP address, geographical location, browser type, referral source, length of visit and number of page views);</p>
                <p>(b) information that you provide to us for the purpose of subscribing to our website services, enquiry/quote form, email notifications and/or newsletters;</p>
                <p>(c) any other information that you choose to send to us.</p>
            </div>
            
            <div>
                <h2>Cookies</h2>
                <p>A cookie consists of information sent by a web server to a web browser, and stored by the browser. The information is then sent back to the server each time the browser requests a page from the server. This enables the web server to identify and track the web browser.</p>
                <p>We use both "session" cookies and "persistent" cookies on the website. We will use the session cookies to: keep track of you whilst you navigate the website. We will use the persistent cookies to: enable our website to recognise you when you visit.</p>
                <p>Session cookies will be deleted from your computer when you close your browser. Persistent cookies will remain stored on your computer until deleted, or until they reach a specified expiry date. </p>
                <p>We use Google Analytics to analyse the use of this website. Google Analytics generates statistical and other information about website use by means of cookies, which are stored on users' computers. The information generated relating to our website is used to create reports about the use of the website.</p>
                <p>Most browsers allow you to refuse to accept cookies. (For example, in Internet Explorer you can refuse all cookies by clicking "Tools", "Internet Options", "Privacy", and selecting "Block all cookies" using the sliding selector.) This will, however, have a negative impact upon the usability of many websites.</p>
            </div>

            <div>
                <h2>Using your personal data</h2>
                <p>Personal data submitted on this website will be used for the purposes specified in this privacy policy or in relevant parts of the website.</p>
                <p>We may use your personal information to: </p>
                <p>(a) enable your use of the services available on the website; </p>
                <p>(b) send you general, not marketing, commercial communications; </p>
                <p>(c) send you email notifications which you have specifically requested;</p>
                <p>(d) send to you marketing communications relating to our business which we think may be of interest to you by post or, where you have specifically agreed to this, by email or similar technology (you can inform us at any time if you no longer require marketing communications); </p>
                <p>(e) deal with enquiries and complaints made by or about you relating to the website. </p>
                <p>We will not without your expressed consent provide your personal information to any third parties for the purpose of direct marketing.</p>
            </div>

            <div>
                <h2>Disclosures</h2>
                <p>We may disclose information about you to any of our employees, officers, agents, suppliers or subcontractor] insofar as reasonably necessary for the purposes as set out in this privacy policy.</p>
                <p>In addition, we may disclose information about you:</p>
                <p>(a) to the extent that we are required to do so by law;</p>
                <p>(b) in connection with any legal proceedings or prospective legal proceedings;</p>
                <p>(c) in order to establish, exercise or defend our legal rights (including providing information to others for the purposes of fraud prevention and reducing credit risk); and</p>
                <p>(d) to the purchaser or prospective purchaser of any business or asset which we are selling.</p>
                <p>Except as provided in this privacy policy, we will not provide your information to third parties.</p>
            </div>  

            <div>
                <h2>International data transfers</h2>
                <p>Information that we collect may be stored and processed in and transferred between any of the countries in which we operate in order to enable us to use the information in accordance with this privacy policy.</p>
                <p>Information which you provide may be transferred to countries which do not have data protection laws equivalent to those in force in the European Economic Area.</p>
                <p>You expressly agree to such transfers of personal information.</p>
            </div>

            <div>
                <h2>Security of your personal data</h2>
                <p>We will take reasonable technical and organisational precautions to prevent the loss, misuse or alteration of your personal information.</p>
                <p>We will store all the personal information you provide on our secure password- and firewall- protected servers.</p>
                <p>Of course, data transmission over the internet is inherently insecure, and we cannot guarantee the security of data sent over the internet.</p>
            </div>

            <div>            
                <h2>Policy amendments</h2>
                <p>We may update this privacy policy from time-to-time by posting a new version on our website. You should check this page occasionally to ensure you are happy with any changes.</p>
            </div>

            <div>            
                <h2>Your rights</h2>
                <p>You may instruct us not to process your personal data for marketing purposes by email at any time. (In practice, you will usually either expressly agree in advance to our use of your personal data for marketing purposes, or we will provide you with an opportunity to opt-out of the use of your personal data for marketing purposes.)</p>
            </div>

            <div>
                <h2>Third party websites</h2>
                <p>The website may contain links to other websites. We are not responsible for the privacy policies or practices of third party websites.</p>
            </div>

            <div>
                <h2>Contact</h2>
                <p>If you have any questions about this privacy policy or our treatment of your personal data, please contact us at hello@docubric.com.</p>
            </div>

        </div>
        
    </div>
  )
}

export default PPSection1