import React, {useEffect} from "react";

import "./packagesbox.scss";

import Tab from "../Tabs/Tabs2";

import AnimatedImg from "./AnimatedImg";
// import AnimGif from "./Discussion.gif";

import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";


import freeGif from "../../../../images/services-page/free-gif.gif";

const animVariants = {
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  hidden: { opacity: 0, y: 200 }
};

const Packages = () => {
    const controls = useAnimation();
    const [inViewref, inView] = useInView();

    useEffect(() => {
      if (inView) {
        setTimeout(() => {
          controls.start("visible");
          document.querySelector('#freepik_stories-discussion').classList.add('animated');
        }, 200);
      
      }
    }, [controls, inView]);


    return (
      <div className="packages" >
        <div id="packges-link"></div>
        <div className="packages-header" >
          <h2>Packages</h2>
        </div> 

        {/* //TODO: This is First Tab */}
        <div
          className="packages--container">
          <div className="packages--content">
            <Tab
              className="package--container-items"
              header="Starter"
              firstPara="Start a one-time project to see if it is a fit for your company. Let us know your needs as it relates to case studies, white papers, or presentations. Our experts work with you to turn your ideas into meeting your goals."
              secondPara=""
            />

            <Tab
              className="package--container-items"
              header="Monthly"
              firstPara="Expand your relationship and get a monthly discount. Get premium support and leadership access. Quality and quick turnarounds. Perfect for returning clients that desire flexibility."
              secondPara=""
              isPopular={true}
            />

            <Tab
              className="package--container-items"
              header="Enterprise"
              firstPara="Pinnacle of client experience for you. Free templates, exclusive emails, offers, and 24/7 support. We work with you to innovative on your ideas. Join the Docubric network of enterprise clients."
              secondPara=""
            />
          </div>
        </div>

      <motion.div
        ref={inViewref}
        animate={controls}
        initial="hidden"
        variants={animVariants}
        className="animation-banner"
        >
        <div className="small-banner">
          <div className="small-banner--text">
            {/* <span>Free</span> */}
            <img src={freeGif} alt="freegif" />
            <h2>Initial Consultation Support</h2>
            <p>Let's discuss your needs and collaborate on a solution together.</p>
          </div>
            <AnimatedImg  />
        </div>
      </motion.div>


      </div>
    );
};

export default Packages;
