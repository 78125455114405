import {useEffect} from 'react'

import { 
    Navbar, 
    CustomBanner, 
    BottomBanner,
    Footer,
    ChatBubble
} from "../../components";


import {
  PackagesCompBox
} from "./services-components";

import servicesBanner from "../../images/services-page/top-banner-services.png";

import ReactGA from 'react-ga';

const Services = (props) => {

  useEffect(() => {
      ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  useEffect(() => {
    document.title = props.title 
    document.querySelector('meta[name="description"]').setAttribute('content', props.description)
  }, [props.title, props.description]);
  
  return (
    <>
        <Navbar />
        <CustomBanner bgImg={servicesBanner} headText="Global brands to startups or small businesses we're here for all" />
        <ChatBubble />
        <PackagesCompBox />
        <BottomBanner paddingTop="80px"/>
        <Footer />
    </>
  )
}

export default Services