import React from 'react'


import contactBg from "../../../../images/contact-page/contact-us-happy-businessman-holding-mobile-smartphone-with-mail-phone-email-icons copy.png"

// import {Link} from 'react-router-dom';
// import { BlueButton } from "../../../../components";

// import { HashLink } from 'react-router-hash-link'

import "./topbannercontact.scss"


const TopBannerContact = () => {

  const scrollToForm = () => {
    window.location = window.location.pathname + "#contact-form"
  }

  return (
    <div className="custom-banner">
        <img id="img-main-contact" src={contactBg} alt="custom-banner--img" />

        <div className="custom-banner--text">
          <h1>We’re glad you stopped here. Let’s get in touch</h1>

            <div className="custom-banner--button">
            <button className="blue-button-main"
              onClick={() => scrollToForm()}
            >
                Send Email
            </button>
                {/* https://api.whatsapp.com/send?phone=8801303546803&text=Hello%20👋 */}
                <a href="https://calendly.com/docubric/30" target="_blank" rel="noopener noreferrer">
                  <button className="blue-button-main">
                    Book A Meeting
                </button>
                </a>
            </div>
        </div>

        

    </div>
  )
}

export default TopBannerContact