import React from "react";

import "./section3.scss";
import founderProfile from "../../../../images/about-page/founder-profile.jpg";
import aboutSideBanner2 from "../../../../images/about-page/about-side-banner2-01.svg"

const Section3 = () => {
  return (
    <>
      <div className="about-section3">
        <div className="about-section3--content">
          <div className="about-section--container-profile">
            <img
              id="founder-profile"
              src={founderProfile}
              alt="founder-profile"
            />
          </div>
          <div className="about-section3--text">
            <h1>The Founder - S M JOY</h1>
            <p>
              This document writing and design company was founded by S M Joy.
              It is his passion for helping clients achieve their business goals
              through content and design for presentations, white papers, and
              case studies that has elevated them to the position of a leader
              within this space.
            </p>
            <p>
              The high standards of professionalism and quality outcomes that
              Joy has inculcated in the company is what has made key partners
              entrust Docubric with projects for developing business documents
              to facilitate effective communication and presentation of facts.
              The number of clients has consistently grown to over 300 clients
              drawn from the media, advertising, technology, financial services,
              venture capital & private equity, accounting, and consulting
              industries.
            </p>
          </div>
        </div>
      </div>
      
      <div className="about-section4">
        <div className="about-section4--content">
            <div className="about-section4--content-text">
                <h1>Vision</h1>
                <p>Our vision is to become a trusted partner for our clients.</p>
                <h1>Mission</h1>
                <p>Our mission is to be a trusted partner for document solutions by creating an unbeatable client experience which facilitates more effective communication.</p>
            </div>
            <div className="about-section4--content-img">
                <img src={aboutSideBanner2} alt="Side-banner2" width="600px"/>
            </div>
        </div>
      </div>
    </>
  );
};

export default Section3;
