import React, { useState } from "react";

import "./section6.scss";

import nextBtn from "../../images/arrow-right.svg";
import prevBtn from "../../images/arrow-left.svg";

import alanJOmarProfile from "../../images/clients/Alan J. Omar.jpg";
import nasirkootioProfile from "../../images/clients/Nasir - Kootio.jpg";
import wasifTahir from "../../images/clients/wasiftahir.jpg";
import meganProfile from "../../images/clients/Megan.jpg";
import ambrossProfile from "../../images/clients/Ambrose Akenji-01.jpg";

const testimonials = [
  {
    name: "Alan J. Omar",
    company: "CEO - The Capital Gate",
    img: alanJOmarProfile,
    testimonial:
      "As a business owner I’ve been very impressed with the high quality of design and research I’ve received from Docubric. We have worked on many projects and they have always exceeded our expectations. Their service is invaluable to new and established entrepreneurs and business owners.",
  },
  {
    name: "Nasir Rizvi",
    company: "CEO - Kootio",
    img: nasirkootioProfile,
    testimonial:
      "Docubric have been essential for our business needs. We have been impressed by the quality, level of service, and dedication of the team. Their deliverables have exceeded expectations and generated great feedback. We encourage anyone in need of document writing and/or design to consider Docubric.",
  },
  {
    name: "Megan McGlover",
    company: "Comedic Motivator. Speaker. Writer",
    img: meganProfile,
    testimonial: 
      "S M Joy is EXCELLENT! His work is innovative, creative, artistic, and extremely thorough. I had high expectations and he met all of them. He worked around the clock at odd hours of the night to complete this at a certain time. I am truly looking forward to working with him again in the very near future. Thank you for all your hard work."
  },
  {
    name: "Wasif Tahir",
    company: "Founder & CEO, ChaseROI",
    img: wasifTahir,
    testimonial: 
    "I simply loved working with Docubric. The quality was so good for our capabilities statement. Thank you so much to Joy, we had no words to express our gratitude for their commitment to quality."
  },
  {
    name: "Ambrose A. M.",
    company: "Co-Founder of VIPsocio",
    img: ambrossProfile,
    testimonial: 
      "On behalf of my team, I wanted to thank you for your professionalism. I think you have a gift for artistry and I enjoyed the way it came out in your works. The responses were always prompt, you were a keen listener and I was very satisfied with my end product. Looking forward to doing some more work with you."
  },
];


const Section6 = () => {
  const [current, setCurrent] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(testimonials[current]);


  function handleAnimtion(direction) {
    // document.querySelectorAll(".testimonial-item").forEach((next) => {
    //   next.style.transition = "all 0.4s ease-in-out";
    //   next.style.opacity = "0";

    //   setTimeout(() => {
    //     if (direction === "right"){
    //       setCurrent(current + 1)
    //     }
    //     else if (direction === "left"){ 
    //       setCurrent(current - 1); 
    //     }
        
    //     if (current < 0) {
    //       setCurrent(curr => (testimonials.length-curr));
    //     } 
    //     else if (current >= (testimonials.length-1)) {
    //       setCurrent(0);
    //     } 

    //     setCurrentSlide(testimonials[current]);

    //     console.log("UPDATED current", testimonials[current]);

    //     setTimeout(() => {
    //       next.style.opacity = "1";
    //     }, 265);
    //   }, 300);

     
    // });


    if (direction === "right"){
      if (current === (testimonials.length-1)) {
        setCurrent(0)
      } else {
        setCurrent(current + 1)
      }
    }
    else if (direction === "left"){ 
      if (current === 0) {
        setCurrent(testimonials.length-1);
      } else {
        setCurrent(current - 1); 
      }
    }
    
    setCurrentSlide(testimonials[current]);

   
  }

  return (
    <div className="section6">
      <div className="section6--bg">
        <div className="section6--header">
          <h1>
            People enjoyed <span>working with us</span>
        </h1>
      </div>

        <div className="section6--content">
          <img
            src={prevBtn}
            id="slide-next"
            onClick={() => handleAnimtion("left")}
            alt="prev-btn"
            width="35px"
          />        
          <img
            src={nextBtn}
            id="slide-prev"
            onClick={() => handleAnimtion("right")}
            alt="next-btn"
            width="35px"
          />

        <div className="testimonial-slide">
            <div className="testimonial-slide--content testimonial-item">
              <p>“{currentSlide?.testimonial}”</p>
            </div>
            <div className="testimonial-slide--info testimonial-item">
              <div className="testimonial-slide--info--profile">
                <img src={currentSlide?.img} alt={`profileimage-${currentSlide?.name}`} width="100px"/>
              </div>

              <div className="testimonial-slide--info--text">
                <h3>{currentSlide?.name}</h3>
                <p>{currentSlide?.company}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section6;
