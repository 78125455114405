import React from 'react'


import "./section5.scss"

import kootioLogo from '../../images/company-logos/kootio-01.svg';
import theafton from '../../images/company-logos/The_afton_logo.svg';
import kpmgLogo from "../../images/company-logos/kpmg-01.svg";
import wreusableLogo from "../../images/company-logos/wreusable-01.svg";
import wrlnetworkLogo from "../../images/company-logos/wrlnetwork-01.svg";
import tgcLogo from "../../images/company-logos/TCG.svg";
import lanaLogo from "../../images/company-logos/Lana.svg";

const Section5 = () => {
  return (
    <div className="section5">
        <div className="section5--head">
            <h1>Why partner <span>with us?</span></h1>
            <p>Your trusted document writing and design companion. As part of the commitment to our philosophy of customer-centricity, we ensure that everything we do is in the best interests of our clients. We strive to be honest, reliable, and supportive partners to all our clients by offering the highest quality of document writing and design services.</p>
        </div>

        <div className="section5-logos">
            <img className="section5-logo kootio-logo" src={kootioLogo} alt="kootio" />
            <img className="section5-logo" src={theafton} alt="fedex" />
            <img className="section5-logo lana-logo" src={lanaLogo} alt="lana"/>
            <img className="section5-logo" src={wreusableLogo} alt="wreusable" />
            <img className="section5-logo" src={tgcLogo} alt="tgcLogo" width="110px" />
            <img className="section5-logo" src={wrlnetworkLogo} alt="wrlnetwork" />
        </div>
    </div>
  )
}

export default Section5