import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';

import App from './App';
import About from "./pages/About/About";
import Services from "./pages/Services/Services";
import Contact from "./pages/Contact/Contact"
import { ScrollToTop } from './components';
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Careers from './pages/Hiring/Career';
import Partnership from './pages/Partnership/Partnership';
import Blog from './pages/Blog/Blog';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import ReactGA from 'react-ga';

const TRACKING_ID = "UA-233166752-1";

ReactGA.initialize(TRACKING_ID, {debug: false});


const rootElement = document.getElementById("root");

const routes = (
  <BrowserRouter>
    <ScrollToTop>
    <Routes>
        <Route path="/" element={<App title="Content Marketing for Professional Services - Docubric" description="Accelerate sales in accounting, consulting, financial services, and more. Maximize your content marketing to convert deals. Become a trusted advisor."/>} />
        <Route path="/about" element={<About title="Who We Are - Docubric" description="Docubric accelerates sales for professional services clients with long-form content marketing. 500+ clients. Big 4, Fortune 100, & more."/>} />
        <Route path="/services" element={<Services title="What We Do - Docubric" description="Docubric provides content writing and graphic design solutions for professional services firms. We create content that converts."/>} />
        <Route path="/contact" element={<Contact title="See It Live - Docubric" description="Let us know how we can help you in your journey to convert sales and expand clients."/>} />
        <Route path="/blog" element={<Blog title="Featured Insights - Docubric" description="Learn more about long form content marketing including case studies, white papers, and presentations. Maximize growth."/>} />
        <Route path="/careers" element={<Careers title="Work With Us - Docubric" description="Come join the team at Docubric. We are always looking for talent. Send us your portfolio today."/>} />
        <Route path="/partnership" element={<Partnership title="Docubric - Partnership" description="Let us know how we can expand your agency or firm's capabilities with our white label content marketing services."/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy title="Partner with Docubric - Docubric" description="Accelerate sales in accounting, consulting, financial services, and more. Maximize your content marketing to convert deals. Become a trusted advisor."/>} />
      </Routes>
    </ScrollToTop>
    </BrowserRouter>
)


if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(routes, rootElement);
} else {
  ReactDOM.render(routes, rootElement);
}