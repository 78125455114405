import {useEffect} from 'react'

import { 
    Navbar, 
    Footer,
    ChatBubble
} from "../../components";


import PPSection1 from './Section-1/PPSection1';

import ReactGA from 'react-ga';



const PrivacyPolicy = (props) => {
  useEffect(() => {
      ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  useEffect(() => {
    document.title = props.title 
    document.querySelector('meta[name="description"]').setAttribute('content', props.description)
  }, [props.title, props.description]);
  return (
    <>
        <Navbar />
        <ChatBubble />
        <PPSection1 />s
        <Footer />
    </>
  )
}

export default PrivacyPolicy