import React from 'react'


import "./custombanner.scss"
import "../BlueButton/BlueButton";

const CustomBanner = ({bgImg, headText}) => {
  return    (
    <div className="custom-banner">
        <img id="img-main" src={bgImg} alt="custom-banner--img" />
        <div className="custom-banner--text">
          <h1>{headText}</h1>
        </div>

    </div>
  )
}

export default CustomBanner