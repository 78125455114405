import React from 'react'

import "./section4.scss"

import artboard1 from "../../images/artboards/artboard-1-01.svg";
import artboard2 from "../../images/artboards/artboard-2-01.svg";
import artboard3 from "../../images/artboards/artboard-3-01.svg";

import { HashLink } from 'react-router-hash-link';

const Section4 = () => {
  return (
    <div className="section4">
      <div className="section4--head">
        <h1>Services</h1>
        <h2>We are a <span>customer-centric business</span></h2>
      </div>

      <div className="section4--card-container">
        <div className="section4--card-content">
            <img src={artboard1} alt="artboard1" width="500px" />
            <div>
              <h1>Presentations/Pitch Deck</h1>
              <p>Presentations are vital tools that help a business to communicate its purpose, objectives, values, strategies, and vision. Presentations are particularly useful for obtaining the support of investors to fund a venture.</p>
              <HashLink to="/services/#packges-link"><button>Explore More</button></HashLink>
            </div>
        </div>

        <div className="section4--card-content">
            <div>
              <h1>White Papers</h1>
              <p>The importance of white papers to businesses cannot be understated. We help businesses design and write quality white papers that effectively communicate their specific competencies and expertise.</p>
              <HashLink to="/services/#packges-link"><button>Explore More</button></HashLink>
              
            </div>
            <img src={artboard2} alt="artboard1" width="500px" />
        </div>

        <div className="section4--card-content">
            <img src={artboard3} alt="artboard1" width="500px" />
            <div>
              <h1>Case Studies</h1>
              <p>Case studies provide the needed context for your company’s past successes to win business. Buyers look to experience and with a well-crafted case study that allows you to stand out amongst your peers.</p>
              <HashLink to="/services/#packges-link"><button>Explore More</button></HashLink>
            </div>
        </div>

      </div>
      
    </div>
  )
}

export default Section4