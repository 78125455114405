import React, {useEffect} from 'react'

import "./section2.scss"
import bulbIcon from "../../images/light-bulb-01.svg";
import peopleIcon from "../../images/people-stting-01.svg";
import handShakeIcon from "../../images/handshake-01.svg";
import growthIcon from "../../images/growth-icon.svg";

import { useAnimation, motion } from "framer-motion";

const animVariants1 = {
    visible: { opacity: 1, y: 0, transition: { duration: .3 } },
    hidden: { opacity: 0, y: 100 }
};
const animVariants2 = {
    visible: { opacity: 1, y: 0, transition: { duration: .4 } },
    hidden: { opacity: 0, y: 100 }
};
const animVariants3 = {
    visible: { opacity: 1, y: 0, transition: { duration: .5 } },
    hidden: { opacity: 0, y: 100 }
};
const animVariants4 = {
    visible: { opacity: 1, y: 0, transition: { duration: .6 } },
    hidden: { opacity: 0, y: 100 }
};

const Cards = () => {
    const controls1 = useAnimation();
    const controls2 = useAnimation();
    const controls3 = useAnimation();
    const controls4 = useAnimation();


    return (
        <div className="cards-container">
            <div className="cards-container--header">
                {/* slowloy slide up */}
                {/* <motion.div
                    style={{zIndex: -100}}
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: .7 }}
                >
                <h1>Problems with <span>poor documents</span></h1>
                </motion.div> */}
                <h1>Problems with <span>poor documents</span></h1>
                
            </div>

            <div className="cards-container--items">

                {/* <motion.div
                    animate={controls1}
                    initial="hidden"
                    whileInView={"visible"}
                    variants={animVariants1}
                    viewport={{ once: true }}
                >
                    <div className="cards-container--card">
                        <div className="section3--container--card--head">
                            <h3>Key initatives stall</h3>
                            <img src={bulbIcon} alt="icon-5" width="40px"/>
                        </div>

                        <p>Whole projects can fail because there was poor communication and some critical information was either misunderstood or not passed successfully at all.</p>
                    </div>
                </motion.div> */}

                <div className="cards-container--card">
                        <div className="section3--container--card--head">
                            <h3>Key initatives stall</h3>
                            <img src={bulbIcon} alt="icon-5" width="40px"/>
                        </div>

                        <p>Whole projects can fail because there was poor communication and some critical information was either misunderstood or not passed successfully at all.</p>
                    </div>

                {/* <motion.div
                    animate={controls2}
                    initial="hidden"
                    whileInView={"visible"}
                    viewport={{ once: true }}
                    variants={animVariants2}
                >
                    <div className="cards-container--card">
                        <div className="section3--container--card--head">
                            <h3>Deals are lost</h3>
                            <img src={handShakeIcon} alt="handshake-icon" width="35px"/>
                        </div>
                        <p>Cases where business or investment deals have already been initiated, poor documentation of facts and figures can also put everything in jeopardy.</p>
                    </div>
                </motion.div> */}

                <div className="cards-container--card">
                        <div className="section3--container--card--head">
                            <h3>Deals are lost</h3>
                            <img src={handShakeIcon} alt="handshake-icon" width="35px"/>
                        </div>
                        <p>Cases where business or investment deals have already been initiated, poor documentation of facts and figures can also put everything in jeopardy.</p>
                    </div>

            </div>

            <div className="cards-container--items">
                    {/* <motion.div
                    animate={controls3}
                    initial="hidden"
                    whileInView={"visible"}
                    viewport={{ once: true }}
                    variants={animVariants3}
                    >
                        <div className="cards-container--card">
                            <div className="section3--container--card--head">
                                <h3>Investors lose interest</h3>
                                <img src={peopleIcon} alt="people-icon" width="35px"/>
                            </div>
                            <p>The poor communication, organization, and presentation of facts, investors end up unimpressed. They aren't able to understand business’s plans, objectives, and proposals.</p>
                        </div>
                    </motion.div> */}

                        <div className="cards-container--card">
                            <div className="section3--container--card--head">
                                <h3>Investors lose interest</h3>
                                <img src={peopleIcon} alt="people-icon" width="35px"/>
                            </div>
                            <p>The poor communication, organization, and presentation of facts, investors end up unimpressed. They aren't able to understand business’s plans, objectives, and proposals.</p>
                        </div>



                    {/* <motion.div
                        animate={controls4}
                        initial="hidden"
                        whileInView={"visible"}
                        viewport={{ once: true}}
                        variants={animVariants4}
                    >
                        <div className="cards-container--card">
                            <div className="section3--container--card--head">
                                <h3>Competitors win</h3>
                                <img src={growthIcon} alt="growth-icon" width="35px"/>
                            </div>
                            <p>Once projects are stalled, investors lost, and deals are cancelled, competitors get a head-start in the game and they are able to make inroads into your market segment.</p>
                        </div>
                    </motion.div> */}

                        <div className="cards-container--card">
                            <div className="section3--container--card--head">
                                <h3>Competitors win</h3>
                                <img src={growthIcon} alt="growth-icon" width="35px"/>
                            </div>
                            <p>Once projects are stalled, investors lost, and deals are cancelled, competitors get a head-start in the game and they are able to make inroads into your market segment.</p>
                        </div>
                </div>

        </div>
    )
}

export default Cards