import { useEffect } from 'react'


import { 
    Navbar, 
    Footer,
    ChatBubble
} from "../../components";


import {
    TopBannerContact,
    ContactSection2,
    ContactForm
} from "./contact-components"


import ReactGA from 'react-ga';


const Contact = (props) => {
  useEffect(() => {
      ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  useEffect(() => {
    document.title = props.title 
    document.querySelector('meta[name="description"]').setAttribute('content', props.description)
  }, [props.title, props.description]);
  
  return (
    <>
      <Navbar />
      <TopBannerContact />
      <ChatBubble />
      <ContactSection2 />
      <ContactForm />
      {/* <NewsLetter paddingTop="100px" paddingBottom="100px"/> */}
      <Footer />
    </>
  )
}

export default Contact