import React from 'react'

import banner from "../../images/background-img.jpg";

import "./banner.scss";

import { motion } from "framer-motion";


const Banner = () => {
  return (
    <div className="top-banner">
        {/* <img src={banner} alt="front-banner" />
        <div className="top-banner--text">
          <motion.div
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: .7 }}
          >
          <h1>Trusted Partner <span>with better communication</span></h1>
          </motion.div>
          <motion.div
                initial={{ opacity: 0, x: -100}}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: .9 }}
          >
           <h3>High-quality business documents leading to effective outcomes. We will be your trusted advisor for presentations, white papers, and case studies to drive value in your organization</h3>
          </motion.div>
          
        </div> */}
        <div className="top-banner">
        <img src={banner} alt="front-banner" />
        <div className="top-banner--text">
          <h1>Trusted Partner for <br/> Better Communication</h1>
          <h3>High-quality business documents leading to effective outcomes. We will be your trusted advisor for presentations, white papers, and case studies to drive value in your organization</h3>
        </div>
    </div>
    </div>
  )
}

export default Banner