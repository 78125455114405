import React from 'react'

import "./section5.scss"

import trustIcon from "../../../../images/about-page/protected-icon-01.svg";
import badgeIcon from "../../../../images/about-page/badge-icon-01.svg";
import passionIcon from "../../../../images/about-page/passion-icon-01.svg";
import chatBoubleIcon from "../../../../images/about-page/chat-bubble-icon-01.svg";
import innovationIcon from "../../../../images/about-page/inovation-icon-01.svg";


const Section5 = () => {
  return (
    <div className="about-section5">
        <div className="about-section5--content">
            <div className="about-section5--content-pair">
                <div className="about-section5--content-card">
                    <img src={trustIcon} alt="trustIcon" width="100px"/>
                    <h3>Trust</h3>
                    <p>The foundation for each of our client relationships is a trusted partnership</p>
                </div>

                <div className="about-section5--content-card">
                    <img src={badgeIcon} alt="badgeIcon" width="100px"/>
                    <h3>Quality</h3>
                    <p>We relentlessly pursue excellence in the work we deliver</p>
                </div>
            </div>

            <div className="about-section5--content-pair">
                <div className="about-section5--content-card">
                    <img src={passionIcon} alt="passionIcon" width="100px"/>
                    <h3>Passion</h3>
                    <p>It is in our DNA to be the best as we love what we do</p>
                </div>

                <div className="about-section5--content-card">
                    <img src={chatBoubleIcon} alt="chatBoubleIcon" width="100px" />
                    <h3>Transparency</h3>
                    <p>Open and honest communication ensures alignment in expectations</p>
                </div>

                <div className="about-section5--content-card">
                    <img src={innovationIcon} alt="innovationIcon" width="100px"/>
                    <h3>Innovation</h3>
                    <p>Constantly stretching ourselves to deliver an exceptional experience</p>
                </div>
            </div>
            
        </div>
    </div>
  )
}

export default Section5