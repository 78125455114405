import { useEffect } from 'react'

import { 
    Navbar, 
    Footer,
    ChatBubble
} from "../../components";

import "./blog.scss"

import ReactGA from 'react-ga';



const Blog = (props) => {
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    useEffect(() => {
        document.title = props.title 
        document.querySelector('meta[name="description"]').setAttribute('content', props.description)
    }, [props.title, props.description]);

    return (
        <>
                <Navbar />
                <ChatBubble />
                <div className="blog-section1">
                    <h1>Coming Soon!</h1>
                </div>
                <Footer />
        </>
        
    )
}

export default Blog