import React from 'react'


import "./bottombanner.scss";
import bottomBannerImg from "../../images/bottom-banner-bg.png";
import { HashLink as Link } from 'react-router-hash-link';

function normalBanner(paddingTop, isAbout) {
  return (
    <div className="bottom-banner" style={{paddingTop }}>
        <div className="bottom-banner--content">
            <div className="bottom-banner--text">
            <h1>We want to hear from you. <Link rel="canonical" to="/contact/#contact-form"><span>Let's Discuss</span></Link></h1>
            </div>
            <div className="bottom-banner--img">
                <img src={bottomBannerImg} alt="Bottom banner" width="100%" />
            </div>
        </div>
    </div>
  )
}

function customBanner(paddingTop) {
  return (
    <div className="bottom-banner" style={{paddingTop }}>
        <div className="bottom-banner--content">
            <div className="bottom-banner--text-custom">
                <h2>We want to hear from you. <Link rel="canonical" to="/contact/#contact-form"><span>Let's Discuss</span></Link></h2>
            </div>
        </div>
    </div>
  )
}

const BottomBanner = ({paddingTop, isAbout}) => {
  // check if doPad is true if yes then add 80px pad top
  if (isAbout) {
    return customBanner(paddingTop)
  } else {
    return normalBanner(paddingTop, isAbout)
  }
}

export default BottomBanner