import React from 'react'

import "./section2.scss"

import aboutSideBanner from "../../../../images/docubric-Icon.svg";


const Seciton2 = () => {
  return (
    <div className="seciton2">
      <div className="section2--container">
        <div className="section2--head">
          <h1>Who <span>we are?</span> </h1>
        </div>
          <div className="section2-container--text">
            <h2>Through dedicated customer service and consistent hard work, Docubric has grown to help over 300 clients create quality business documents for effective communication and enhanced business outcomes. Five years ago, we were helping individuals and organizations with structuring and formatting their presentations. We slowly built our capacity and eventually were able to scale our document writing and design services to reach a larger audience. We sharpened our core competencies and this gave us a firm foundation as a professional business. Additionally, we have worked with Big 4 firms, multinational companies, leading global brands, fast-growing startups, and other well-respected companies in a variety of verticals. The reason our clients have trusted us with their document writing and design needs is because of our rigorous quality standards and customer-centric approach to all the work we do.</h2>
          </div>
          
      </div>

      <div className="section2-container--img">
        <img src={aboutSideBanner} alt="about-page-banner" />
      </div>

    </div>
  )
}

export default Seciton2