import React from 'react'

import "./dialog.scss"
import closeIcon from "../../images/hamburger-close.svg";

const Dialog = ({title, message, innerRef}) => {
  return (
    <div ref={innerRef} className="contact-form-done-dialog" id="contact-form-done-dialog" style={{display: 'none'}}>
          <img src={closeIcon} alt="closeIcon" onClick={() => {
                let dd = document.getElementById("contact-form-done-dialog");
                dd.style.opacity = "0";
                dd.style.display = "none";
            }} />
          <h1>{title}</h1>
          <p>{message}</p>
          <div>
          </div>
          
    </div>
  )
}

export default Dialog