import React from 'react'

import "./tabs2.scss"

import { HashLink } from 'react-router-hash-link';

const Tabs2 = ({
        className, 
        header, 
        firstPara,
        isPopular,
    }) => {
    return (
        <div className="tab2-main">
            <div className="popular-tab" style={
            isPopular === true? {display: "block"} : {display: "none"}}>
                    <h1>Recommended</h1>
                </div>
            <div className={className}>
                <div>
                    <h2>{header}</h2>
                    <h4>{firstPara}</h4>
                </div>

                <div className="packages--content-item-price">
                    <HashLink to="/contact/#contact-form"><button>GET A QUOTE</button></HashLink>
                </div>

            </div>
        </div>
        
    )
}

export default Tabs2