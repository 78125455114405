import React, {useRef} from "react";

import "./contactform.scss";

import "../../../../util.scss";

import { Dialog } from "../../../../components";
import emailjs from '@emailjs/browser';
import { creds } from "../../../../creds";
import ReactGA from 'react-ga';

const ContactForm = () => {
  const form = useRef();
  const dialog = useRef();
  const sendBtn = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    sendBtn.current.disabled = true;
    emailjs.sendForm(creds.serviceID, creds.templateID, form.current, creds.userID)
      .then(
        () => {
          dialog.current.style.opacity = "0.9";
          dialog.current.style.display = "flex";
          sendBtn.current.disabled = false;
          
          ReactGA.event({
            category: "Contact",
            action: "Email sent",
            label: form.current.name.value,
            value: form.current.email.value
          });
        },
        (error) => {
          alert("Email was not sent! Please try again.");
          sendBtn.current.disabled = false;
          console.log(error);
        }
      );

    
  };

  return (
    <div className="contact-form">
      <div className="contact-form--container">
        <Dialog
          title="Thank you for contacting us!"
          message="A member of the Docubric team will reach out soon."
          innerRef={dialog}
        />
        <form action="/" onSubmit={sendEmail} ref={form}>
          <div className="contact-form--content">
            <div className="contact-form--fields">
              {/* text will be added in header with css  */}
              <h1> </h1>
              <div>
                <label htmlFor="name">Name</label>
                <input type="text" name="name" id="name" required />
              </div>

              <div>
                <label htmlFor="email">Email</label>
                <input type="email" name="email" id="email" required />
              </div>

              <div>
                <label htmlFor="phone">Phone Number</label>
                <input type="phone" name="phone" id="phone" required />
              </div>

              <div>
                <label htmlFor="company">Company</label>
                <input type="text" name="company" id="company" required />
              </div>

              <div>
                <label htmlFor="jobTitle">Job Title</label>
                <input type="text" name="jobTitle" id="jobTitle" required />
              </div>

              <div>
                <label htmlFor="message">Message</label>
                <textarea name="message" id="message" required></textarea>
              </div>

              <div className="contact-form--fields-submit">
                <button ref={sendBtn} type="submit" className="submit-btn-blue" id="contact-form-submit-btn">Send Email</button>
              </div>
            </div>

            <div className="contact-form--info">
              <h1> </h1>
              <div>
                <h2>Email</h2>
                <p>
                <a href="mailto:hello@docubric.com">hello@docubric.com</a>
                </p>
              </div>

              <div>
                <h2>Phone</h2>
                <p>
                  Bangladesh:{" "}
                  <a href="tel:+880-1303-546803">+880-1303-546803</a>
                </p>
                
              </div>

              <div>
                <h2>Get a free consultation</h2>
                <p>Book a free call on Calendly</p>
                <a href="https://calendly.com/docubric/30">
                  Book a 30-minute call
                </a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
