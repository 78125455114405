import React from "react";

import "../../../../images/test/discussion-styles.css"

function AnimatedImg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="freepik_stories-discussion"
      version="1.1"
      viewBox="0 0 500 500"
    >
      <g
        id="freepik--background-complete--inject-45"
        style={{
          WebkitTransformOrigin: 250.0000228881836,
          MsTransformOrigin: 250.0000228881836,
          transformOrigin: 250.0000228881836,
        }}
        className="animable"
      >
        <g id="elwxmy9xohebb">
          <circle
            id="elrklt18nog8"
            cx="181.03"
            cy="63.27"
            r="20.45"
            style={{
              WebkitTransformOrigin: 181.03,
              MsTransformOrigin: 181.03,
              transformOrigin: 181.03,
            }}
            fill="#EBEBEB"
            className="animable"
            opacity="0.19"
          ></circle>
        </g>
        <g id="elr7csrjeekbh">
          <circle
            id="el69bywx20dot"
            cx="181.03"
            cy="66.63"
            r="15.17"
            style={{
              WebkitTransformOrigin: 181.03,
              MsTransformOrigin: 181.03,
              transformOrigin: 181.03,
            }}
            fill="#EBEBEB"
            className="animable"
            opacity="0.19"
            transform="rotate(-76.63)"
          ></circle>
        </g>
        <path
          style={{
            WebkitTransformOrigin: 181.0250015258789,
            MsTransformOrigin: 181.0250015258789,
            transformOrigin: 181.0250015258789,
          }}
          id="elu83jsvavt1h"
          fill="#EBEBEB"
          d="M203.36 66.63L189.23 90.71 172.83 90.71 158.69 66.63 203.36 66.63z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 181.03500366210938,
            MsTransformOrigin: 181.03500366210938,
            transformOrigin: 181.03500366210938,
          }}
          id="el0mhe6lrstao"
          fill="#EBEBEB"
          d="M193.07 98.82L169 98.82 172.83 90.71 189.23 90.71 193.07 98.82z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 181.33499908447266,
            MsTransformOrigin: 181.33499908447266,
            transformOrigin: 181.33499908447266,
          }}
          id="elmhcxi16fqic"
          fill="#C7C7C7"
          d="M191.78 86.37L189.23 90.72 172.82 90.72 170.89 87.42 191.78 86.37z"
          className="animable"
        ></path>
        <g id="el5zqupeh61m">
          <circle
            id="els4kyq8hff2m"
            cx="250"
            cy="63.27"
            r="20.45"
            style={{
              WebkitTransformOrigin: 250,
              MsTransformOrigin: 250,
              transformOrigin: 250,
            }}
            fill="#EBEBEB"
            className="animable"
            opacity="0.19"
          ></circle>
        </g>
        <g id="eljlgizblkdkj">
          <circle
            id="el3xhu0m9dfb6"
            cx="250"
            cy="66.63"
            r="15.17"
            style={{
              WebkitTransformOrigin: 250,
              MsTransformOrigin: 250,
              transformOrigin: 250,
            }}
            fill="#EBEBEB"
            className="animable"
            opacity="0.19"
            transform="rotate(-24.18)"
          ></circle>
        </g>
        <path
          style={{
            WebkitTransformOrigin: 249.99999237060547,
            MsTransformOrigin: 249.99999237060547,
            transformOrigin: 249.99999237060547,
          }}
          id="elwidw8up5elr"
          fill="#EBEBEB"
          d="M272.33 66.63L258.2 90.71 241.8 90.71 227.67 66.63 272.33 66.63z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 250.0050048828125,
            MsTransformOrigin: 250.0050048828125,
            transformOrigin: 250.0050048828125,
          }}
          id="eli874kpynvf"
          fill="#EBEBEB"
          d="M262.04 98.82L237.97 98.82 241.8 90.71 258.2 90.71 262.04 98.82z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 250.31000518798828,
            MsTransformOrigin: 250.31000518798828,
            transformOrigin: 250.31000518798828,
          }}
          id="elzycqa8gh9i"
          fill="#C7C7C7"
          d="M260.76 86.37L258.2 90.72 241.8 90.72 239.86 87.42 260.76 86.37z"
          className="animable"
        ></path>
        <g id="elkgwxcf5l6q8">
          <circle
            id="elzigjlu2vlq"
            cx="318.97"
            cy="63.27"
            r="20.45"
            style={{
              WebkitTransformOrigin: 318.97,
              MsTransformOrigin: 318.97,
              transformOrigin: 318.97,
            }}
            fill="#EBEBEB"
            className="animable"
            opacity="0.19"
          ></circle>
        </g>
        <g id="elwz252icfb4b">
          <circle
            id="elolfl05jx46e"
            cx="318.97"
            cy="66.63"
            r="15.17"
            style={{
              WebkitTransformOrigin: 318.97,
              MsTransformOrigin: 318.97,
              transformOrigin: 318.97,
            }}
            fill="#EBEBEB"
            className="animable"
            opacity="0.19"
            transform="rotate(-45)"
          ></circle>
        </g>
        <path
          style={{
            WebkitTransformOrigin: 318.9750061035156,
            MsTransformOrigin: 318.9750061035156,
            transformOrigin: 318.9750061035156,
          }}
          id="elbn9yq29lwgv"
          fill="#EBEBEB"
          d="M341.31 66.63L327.17 90.71 310.77 90.71 296.64 66.63 341.31 66.63z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 318.9750061035156,
            MsTransformOrigin: 318.9750061035156,
            transformOrigin: 318.9750061035156,
          }}
          id="eltzlnrc08jlj"
          fill="#EBEBEB"
          d="M331.01 98.82L306.94 98.82 310.77 90.71 327.17 90.71 331.01 98.82z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 319.2850036621094,
            MsTransformOrigin: 319.2850036621094,
            transformOrigin: 319.2850036621094,
          }}
          id="elkx3xc8p3qf"
          fill="#C7C7C7"
          d="M329.73 86.37L327.18 90.72 310.77 90.72 308.84 87.42 329.73 86.37z"
          className="animable"
        ></path>
        <g id="elq5po9zxb8mn">
          <path
            style={{
              WebkitTransformOrigin: 420.65000000000003,
              MsTransformOrigin: 420.65000000000003,
              transformOrigin: 420.65000000000003,
            }}
            id="elz217w4zxtwe"
            fill="#EBEBEB"
            d="M407.41 359.63H433.89000000000004V370.82H407.41z"
            className="animable"
            transform="rotate(180)"
          ></path>
        </g>
        <g id="eln6a6eojx0lk">
          <path
            style={{
              WebkitTransformOrigin: 435.42,
              MsTransformOrigin: 435.42,
              transformOrigin: 435.42,
            }}
            id="elngm6hn28z9"
            fill="#EBEBEB"
            d="M422.18 373.74H448.66V384.93H422.18z"
            className="animable"
            transform="rotate(180)"
          ></path>
        </g>
        <g id="el5jjjssyyd9s">
          <path
            style={{
              WebkitTransformOrigin: 271.18,
              MsTransformOrigin: 271.18,
              transformOrigin: 271.18,
            }}
            id="elxgibhr5fqnk"
            fill="#EBEBEB"
            d="M257.94 197.63H284.42V208.82H257.94z"
            className="animable"
            transform="rotate(180)"
          ></path>
        </g>
        <g id="ely366ucckrr">
          <path
            style={{
              WebkitTransformOrigin: 285.95,
              MsTransformOrigin: 285.95,
              transformOrigin: 285.95,
            }}
            id="elg745c00ji7c"
            fill="#EBEBEB"
            d="M272.71 211.74H299.19V222.93H272.71z"
            className="animable"
            transform="rotate(180)"
          ></path>
        </g>
        <g id="elfk278hxy9nf">
          <path
            style={{
              WebkitTransformOrigin: 300.67,
              MsTransformOrigin: 300.67,
              transformOrigin: 300.67,
            }}
            id="elpgi5zuyj7f"
            fill="#EBEBEB"
            d="M287.43 197.63H313.91V208.82H287.43z"
            className="animable"
            transform="rotate(180)"
          ></path>
        </g>
        <g id="elqc4ydzhzlkc">
          <path
            style={{
              WebkitTransformOrigin: 64.58,
              MsTransformOrigin: 64.58,
              transformOrigin: 64.58,
            }}
            id="elbre6ubf9v9a"
            fill="#EBEBEB"
            d="M51.34 373.74H77.82000000000001V384.93H51.34z"
            className="animable"
            transform="rotate(180)"
          ></path>
        </g>
        <g id="elpibwd1n8mec">
          <path
            style={{
              WebkitTransformOrigin: 79.35,
              MsTransformOrigin: 79.35,
              transformOrigin: 79.35,
            }}
            id="el60kkj579q5f"
            fill="#EBEBEB"
            d="M66.11 46.74H92.59V57.93H66.11z"
            className="animable"
            transform="rotate(180)"
          ></path>
        </g>
        <g id="el4qocnht4zde">
          <path
            style={{
              WebkitTransformOrigin: 64.58,
              MsTransformOrigin: 64.58,
              transformOrigin: 64.58,
            }}
            id="eleaioyoh63n9"
            fill="#EBEBEB"
            d="M51.34 60.85H77.82000000000001V72.04H51.34z"
            className="animable"
            transform="rotate(180)"
          ></path>
        </g>
        <g id="eld24l87timjd">
          <path
            style={{
              WebkitTransformOrigin: 420.65000000000003,
              MsTransformOrigin: 420.65000000000003,
              transformOrigin: 420.65000000000003,
            }}
            id="el659aavqyz43"
            fill="#EBEBEB"
            d="M407.41 46.74H433.89000000000004V57.93H407.41z"
            className="animable"
            transform="rotate(180)"
          ></path>
        </g>
        <g id="eler04642056d">
          <path
            style={{
              WebkitTransformOrigin: 149.29000000000002,
              MsTransformOrigin: 149.29000000000002,
              transformOrigin: 149.29000000000002,
            }}
            id="elncd617nqq7f"
            fill="#EBEBEB"
            d="M136.05 150.69H162.53V161.88H136.05z"
            className="animable"
            transform="rotate(180)"
          ></path>
        </g>
      </g>
      <g
        id="freepik--Floor--inject-45"
        style={{
          WebkitTransformOrigin: 249.99999237060547,
          MsTransformOrigin: 249.99999237060547,
          transformOrigin: 249.99999237060547,
        }}
        className="animable"
      >
        <path
          style={{
            WebkitTransformOrigin: 249.99999237060547,
            MsTransformOrigin: 249.99999237060547,
            transformOrigin: 249.99999237060547,
          }}
          id="elyn994ihv7ll"
          fill="#263238"
          d="M44.35 457.62L95.76 457.38 147.17 457.29 250 457.12 352.83 457.29 404.24 457.37 455.65 457.62 404.24 457.86 352.83 457.95 250 458.12 147.17 457.95 95.76 457.86 44.35 457.62z"
          className="animable"
        ></path>
      </g>
      <g
        id="freepik--character-2--inject-45"
        style={{
          WebkitTransformOrigin: 354.88211822509766,
          MsTransformOrigin: 354.88211822509766,
          transformOrigin: 354.88211822509766,
        }}
        className="animable"
      >
        <path
          style={{
            WebkitTransformOrigin: 316.82501220703125,
            MsTransformOrigin: 316.82501220703125,
            transformOrigin: 316.82501220703125,
          }}
          id="el4jnxojkfxtw"
          fill="#DBDBDB"
          d="M330.26 315.72L327.23 333.89 306.7 457.53 303.39 457.53 318.32 333.89 320.53 315.72 330.26 315.72z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 324.2900085449219,
            MsTransformOrigin: 324.2900085449219,
            transformOrigin: 324.2900085449219,
          }}
          id="elc8vwlwmgq4k"
          fill="#A6A6A6"
          d="M320.53 315.72L330.26 315.72 327.23 333.89 318.32 333.89 320.53 315.72z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 381.2949981689453,
            MsTransformOrigin: 381.2949981689453,
            transformOrigin: 381.2949981689453,
          }}
          id="elz1i9mpttbr"
          fill="#DBDBDB"
          d="M394.73 457.53L391.42 457.53 370.89 333.89 367.86 315.72 377.6 315.72 379.8 333.89 394.73 457.53z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 373.8299865722656,
            MsTransformOrigin: 373.8299865722656,
            transformOrigin: 373.8299865722656,
          }}
          id="elv6ttbjamca"
          fill="#A6A6A6"
          d="M377.6 315.72L379.8 333.89 370.89 333.89 367.86 315.72 377.6 315.72z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 442.875,
            MsTransformOrigin: 442.875,
            transformOrigin: 442.875,
          }}
          id="eloz6nkj3wu2p"
          fill="#DBDBDB"
          d="M456.31 457.53L453 457.53 432.47 333.89 429.44 315.72 439.17 315.72 441.38 333.89 456.31 457.53z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 435.4100036621094,
            MsTransformOrigin: 435.4100036621094,
            transformOrigin: 435.4100036621094,
          }}
          id="elz1cjko4nqmq"
          fill="#A6A6A6"
          d="M441.38 333.89L432.47 333.89 429.44 315.72 439.17 315.72 441.38 333.89z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 375.8450012207031,
            MsTransformOrigin: 375.8450012207031,
            transformOrigin: 375.8450012207031,
          }}
          id="el33u0sgr36eh"
          fill="#DBDBDB"
          d="M451.06 324.26L300.63 324.26 300.63 308.41 356.94 308.41 375.87 198.3 445.32 198.3 451.06 324.26z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 444.2850036621094,
            MsTransformOrigin: 444.2850036621094,
            transformOrigin: 444.2850036621094,
          }}
          id="elz9dx1px1n7"
          fill="#A6A6A6"
          d="M451.06 324.26L442.86 324.26 437.51 198.3 445.32 198.3 451.06 324.26z"
          className="animable"
        ></path>
        <path
          d="M367.87 169.83c-23.11 26.07-44.09 54.12-44.5 67.29-.57 18.23 12.61 20 17.56 19.47 13.47-1.38 30.2-56.69 36.37-78.47 5.98-21.12-4.36-14.01-9.43-8.29z"
          style={{
            WebkitTransformOrigin: 351.23033905029297,
            MsTransformOrigin: 351.23033905029297,
            transformOrigin: 351.23033905029297,
          }}
          id="eli6wsnu8pip"
          fill="#256192"
          className="animable"
        ></path>
        <g id="eljk2xjt4f3z">
          <path
            d="M377.3 178.12c-6.16 21.78-22.9 77.09-36.37 78.47a15.49 15.49 0 01-1.87.06c7.08-18.53 28.24-73.91 35.47-92.23 3.81-1.73 6.55.34 2.77 13.7z"
            style={{
              WebkitTransformOrigin: 359.08438873291016,
              MsTransformOrigin: 359.08438873291016,
              transformOrigin: 359.08438873291016,
            }}
            id="eldzp1mt81c9d"
            className="animable"
            opacity="0.1"
          ></path>
        </g>
        <path
          d="M256.4 456.07c3 .26 77.81-19.43 80.37-22.11 1-1-1.2-12.79-4.25-26.95l-10.08-39.85-48 11.71 8.07 41.56s-23.65 20.31-26.16 23.57-2.96 11.83.05 12.07z"
          style={{
            WebkitTransformOrigin: 295.6582565307617,
            MsTransformOrigin: 295.6582565307617,
            transformOrigin: 295.6582565307617,
          }}
          id="elzf7zhtols0c"
          fill="#AD6359"
          className="animable"
        ></path>
        <path
          d="M255.26 457.32c2.92.2 79.85-19.72 82.3-22.34.94-1-1.46-12.93-4.56-26.6l-52 13s-23 19.95-25.34 23.11-3.32 12.63-.4 12.83z"
          style={{
            WebkitTransformOrigin: 295.61034774780273,
            MsTransformOrigin: 295.61034774780273,
            transformOrigin: 295.61034774780273,
          }}
          id="el9yzhwbm17l7"
          fill="#256192"
          className="animable"
        ></path>
        <path
          d="M255.56 454c12.82-3.66 64.84-17.2 77.79-19.84.1 0 .12.05 0 .08-12.62 3.9-64.76 17-77.75 20-.26-.01-.31-.24-.04-.24z"
          style={{
            WebkitTransformOrigin: 294.40625,
            MsTransformOrigin: 294.40625,
            transformOrigin: 294.40625,
          }}
          id="el2x90auig2ix"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M259.31 441.57a10.68 10.68 0 018.53 1.15 10.4 10.4 0 014.67 6.14c0 .08-.13.12-.16 0a12.36 12.36 0 00-13-7.17.08.08 0 01-.04-.12z"
          style={{
            WebkitTransformOrigin: 265.9032368659973,
            MsTransformOrigin: 265.9032368659973,
            transformOrigin: 265.9032368659973,
          }}
          id="elj3t6ov9u0sl"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M276.23 424.37c4.92-2.06 11.36-2.62 16.06.19.18.11 0 .37-.16.35a74 74 0 00-15.74.1.33.33 0 01-.16-.64z"
          style={{
            WebkitTransformOrigin: 284.1775016784668,
            MsTransformOrigin: 284.1775016784668,
            transformOrigin: 284.1775016784668,
          }}
          id="els6j54ikxldr"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M273 427.39c4.92-2.05 11.35-2.61 16.06.2.18.11 0 .36-.16.35a73.3 73.3 0 00-15.74.1.34.34 0 01-.16-.65z"
          style={{
            WebkitTransformOrigin: 280.96649074554443,
            MsTransformOrigin: 280.96649074554443,
            transformOrigin: 280.96649074554443,
          }}
          id="elqsc8xa4ph37"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M269.79 430.42c4.92-2.06 11.35-2.62 16 .19.19.11 0 .37-.15.35a73.3 73.3 0 00-15.74.1.33.33 0 01-.11-.64z"
          style={{
            WebkitTransformOrigin: 277.7214107513428,
            MsTransformOrigin: 277.7214107513428,
            transformOrigin: 277.7214107513428,
          }}
          id="elf28zneja6wu"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M282 420.55l51-12.17s-15-86.95-12.8-89.92c4.09-5.63 66.37-4.43 85.58-23.52 20.34-20.21 11.46-45.77 11.46-45.77h-55.7s-.44 7.49-1.46 7.79c-12.62 3.68-62.17 5.31-80.2 19.76-4.64 3.72-12.48 13.4-14.08 23-5.07 30.71 16.2 120.83 16.2 120.83z"
          style={{
            WebkitTransformOrigin: 342.0942077636719,
            MsTransformOrigin: 342.0942077636719,
            transformOrigin: 342.0942077636719,
          }}
          id="elaccifs43ke5"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M329.59 399a26.81 26.81 0 00-4.62.67l-4.69.94-9.56 2c-6.36 1.32-12.71 2.69-19 4.19-3.57.84-7.09 1.83-10.63 2.79-.14 0-.08.25.06.23 12.86-2.09 25.53-5 38.22-7.88l5.17-1.21a29.43 29.43 0 005.15-1.47c.07-.1.01-.26-.1-.26z"
          style={{
            WebkitTransformOrigin: 305.36519050598145,
            MsTransformOrigin: 305.36519050598145,
            transformOrigin: 305.36519050598145,
          }}
          id="elsbhgvuacp4c"
          fill="#37474F"
          className="animable"
        ></path>
        <path
          d="M405.78 294.94c-12.47 12.39-43.09 16.23-64.16 18.95l41.2-64.72h34.42s8.88 25.56-11.46 45.77z"
          style={{
            WebkitTransformOrigin: 380.3947525024414,
            MsTransformOrigin: 380.3947525024414,
            transformOrigin: 380.3947525024414,
          }}
          id="el426mwad9awr"
          fill="#37474F"
          className="animable"
        ></path>
        <path
          d="M323.35 394.31c.13.73-.28 1.16-.54 0-6-27.38-10.34-61.55-10.78-66-.23-2.23-.42-4.46-.52-6.7-.05-1.12-.09-2.25-.06-3.38a15.61 15.61 0 01.35-3.43 4.08 4.08 0 01.32-.88 2.27 2.27 0 01.66-.77 6.62 6.62 0 011.58-.86 24 24 0 013.24-1.05c2.18-.57 4.38-1 6.58-1.42 4.4-.8 8.82-1.48 13.23-2.16 8.83-1.32 17.66-2.61 26.43-4.24s17.51-3.52 25.81-6.69c4.09-1.65 8.19-3.62 11-7.08a39.45 39.45 0 007.35-11 47.91 47.91 0 003.41-26.14 48.18 48.18 0 01-3 26.34 39.83 39.83 0 01-7.34 11.23 30 30 0 01-2.37 2.42 24.39 24.39 0 01-2.77 2 42 42 0 01-6.06 3 97.61 97.61 0 01-12.81 4c-4.35 1.09-8.72 2-13.12 2.84-8.78 1.66-17.62 3-26.44 4.33-4.41.66-8.83 1.32-13.21 2.11-2.19.4-4.37.83-6.51 1.38a24.79 24.79 0 00-3.13 1 5.88 5.88 0 00-1.36.74 1.27 1.27 0 00-.42.48 3 3 0 00-.25.68 15.42 15.42 0 00-.34 3.22c0 1.1 0 2.21.05 3.32.1 2.22.28 4.43.49 6.65.44 4.43 1 8.86 1.61 13.28 1.28 8.8 7.36 43.99 8.92 52.78z"
          style={{
            WebkitTransformOrigin: 361.75323486328125,
            MsTransformOrigin: 361.75323486328125,
            transformOrigin: 361.75323486328125,
          }}
          id="elci77wrnfn8k"
          fill="#37474F"
          className="animable"
        ></path>
        <path
          d="M322.18 457.93c2.89.93 68.93-.14 72-2.16 1.2-.75 1.74-12.73 2-27.22v-.46c.06-3 .1-6.06.1-9.18v-1.28c.11-20.92-2.19-42.12-2.19-42.12l-53 .05 1.65 42.22.07 1.67.33 8.74v.9s-15.16 14.47-18.34 17.06-5.5 10.85-2.62 11.78z"
          style={{
            WebkitTransformOrigin: 358.5771255493164,
            MsTransformOrigin: 358.5771255493164,
            transformOrigin: 358.5771255493164,
          }}
          id="elbx1k69mpdn6"
          fill="#AD6359"
          className="animable"
        ></path>
        <path
          d="M319.29 458.21c2.79.9 72.43.28 75.45-1.66 1.15-.73 1.73-12.91 2-26.92H342s-17 14.7-20.08 17.19-5.41 10.48-2.63 11.39z"
          style={{
            WebkitTransformOrigin: 357.39388275146484,
            MsTransformOrigin: 357.39388275146484,
            transformOrigin: 357.39388275146484,
          }}
          id="elguqul25p1th"
          fill="#256192"
          className="animable"
        ></path>
        <path
          d="M320.41 455c13.32-.43 57.26-.92 70.45-.34.11 0 .11.08 0 .09-13.19.72-57.13.77-70.45.47-.28.01-.28-.22 0-.22z"
          style={{
            WebkitTransformOrigin: 355.57125091552734,
            MsTransformOrigin: 355.57125091552734,
            transformOrigin: 355.57125091552734,
          }}
          id="eloz0waq0aqi9"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M323.25 446.54c7.28-3.57 15.58-4.47 21-3.3 5.19 1.12 10.36 3.67 12.17 8.18.05.13-.29.29-.38.17-5.6-7.51-20-9.77-32.65-4.78-.27.1-.39-.15-.14-.27z"
          style={{
            WebkitTransformOrigin: 339.76911544799805,
            MsTransformOrigin: 339.76911544799805,
            transformOrigin: 339.76911544799805,
          }}
          id="elnukxqov2vng"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M340.48 437.55a41.61 41.61 0 0124.9.92c.27.1-.07.45-.34.42-8.25-.85-15.77-1.53-24.5-.54-.6.07-.66-.64-.06-.8z"
          style={{
            WebkitTransformOrigin: 352.7688503265381,
            MsTransformOrigin: 352.7688503265381,
            transformOrigin: 352.7688503265381,
          }}
          id="elb59cz2vwrrd"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M343.28 434.14a41.69 41.69 0 0124.9.93c.27.1-.06.45-.34.42-8.24-.86-15.77-1.53-24.5-.54-.6.05-.65-.65-.06-.81z"
          style={{
            WebkitTransformOrigin: 355.57157135009766,
            MsTransformOrigin: 355.57157135009766,
            transformOrigin: 355.57157135009766,
          }}
          id="eldroqbg1fby5"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M346.08 430.74a41.61 41.61 0 0124.9.93c.27.1-.06.45-.33.42-8.25-.86-15.78-1.53-24.5-.54-.61.06-.66-.65-.07-.81z"
          style={{
            WebkitTransformOrigin: 358.3721971511841,
            MsTransformOrigin: 358.3721971511841,
            transformOrigin: 358.3721971511841,
          }}
          id="elmpiqw6zx649"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M341.46 430.5l56.13-.43s-.39-94.33 2.37-101.64 13-12.71 25.33-27c18.75-21.7 2.38-52.2 2.38-52.2h-54.86s-7.12 18-6.7 25.27c0 0 5.88.37 9.45.38 0 0-25.53 21.11-32.05 40.9-5.58 16.9-2.05 114.72-2.05 114.72z"
          style={{
            WebkitTransformOrigin: 387.32361221313477,
            MsTransformOrigin: 387.32361221313477,
            transformOrigin: 387.32361221313477,
          }}
          id="elahxaocz5lhl"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M391.2 276.08a72.55 72.55 0 00-15.55-1.84 108.88 108.88 0 00-15.84.78.12.12 0 000 .23c5.23.18 10.47.46 15.7.75s10.4.71 15.61.72a.33.33 0 00.08-.64z"
          style={{
            WebkitTransformOrigin: 375.5724639892578,
            MsTransformOrigin: 375.5724639892578,
            transformOrigin: 375.5724639892578,
          }}
          id="el7zemxmmflek"
          fill="#37474F"
          className="animable"
        ></path>
        <path
          d="M418 268.94a39.83 39.83 0 01-7.23-1.64 10.42 10.42 0 01-5.18-2.75c-2.76-3-3.92-7-6.06-10.39-.07-.12-.23 0-.21.09a50.49 50.49 0 002 6.9 14.31 14.31 0 003 5.22c3.43 3.57 9.09 3.11 13.61 3 .39.04.36-.37.07-.43z"
          style={{
            WebkitTransformOrigin: 408.76926708221436,
            MsTransformOrigin: 408.76926708221436,
            transformOrigin: 408.76926708221436,
          }}
          id="elhnr46vxduzc"
          fill="#37474F"
          className="animable"
        ></path>
        <path
          d="M394.56 419.06a28.07 28.07 0 00-4.77-.37c-1.63 0-3.27-.09-4.91-.12l-10-.22c-6.67-.13-13.33-.2-20-.15-3.76 0-7.51.2-11.26.36a.12.12 0 000 .23c13.34.83 26.66.85 40 .83h5.45a31.31 31.31 0 005.48-.28.13.13 0 00.01-.28z"
          style={{
            WebkitTransformOrigin: 369.1146545410156,
            MsTransformOrigin: 369.1146545410156,
            transformOrigin: 369.1146545410156,
          }}
          id="elqjnqnk3ucm"
          fill="#37474F"
          className="animable"
        ></path>
        <path
          d="M368.62 251.44c.26 3.32.44 6.65.85 9.95.19 1.56.33 3.14.64 4.68a3 3 0 00.84 1.78 3.72 3.72 0 002 .68 2.17 2.17 0 002.69-2.12c.28-3-.6-13.52-.62-14.38 0-.17.55-.18.57 0 .82 6.62 1.91 15.07.51 16.51a4.43 4.43 0 01-5.76.41 4.2 4.2 0 01-1.26-2.7c-.21-1.45-.28-2.93-.39-4.38-.25-3.47-.56-6.93-.62-10.4-.01-.32.52-.33.55-.03z"
          style={{
            WebkitTransformOrigin: 372.421573638916,
            MsTransformOrigin: 372.421573638916,
            transformOrigin: 372.421573638916,
          }}
          id="elu2jh2reingc"
          fill="#37474F"
          className="animable"
        ></path>
        <path
          d="M373.58 269a10.8 10.8 0 01.25 1.26c.09.43.18.85.28 1.27s.2.83.31 1.25.18.84.27 1.26a.16.16 0 01-.3.1 10.24 10.24 0 01-.51-1.18c-.14-.42-.28-.84-.39-1.27s-.2-.84-.29-1.26a5.9 5.9 0 01-.23-1.31.33.33 0 01.61-.12z"
          style={{
            WebkitTransformOrigin: 373.83108431100845,
            MsTransformOrigin: 373.83108431100845,
            transformOrigin: 373.83108431100845,
          }}
          id="elqymcmkaalic"
          fill="#37474F"
          className="animable"
        ></path>
        <path
          d="M425.55 254.39a118.29 118.29 0 011.3 21.36 36.22 36.22 0 01-1.81 10.6 20.44 20.44 0 01-2.63 4.73c-1 1.47-2.16 2.87-3.32 4.23-4.64 5.46-9.75 10.47-14.71 15.59-2.48 2.56-4.95 5.13-7.26 7.82a33.19 33.19 0 00-3.15 4.21 14.75 14.75 0 00-1.57 4.9c-.65 3.48-1 7-1.29 10.57s-.51 7.11-.68 10.67c-.35 7.12-.52 14.25-.69 21.38s.14 36.07.16 42.8c0 .4-.55.52-.61 0-.19-7.13-.23-14.26-.32-21.4s-.06-14.27 0-21.41.23-14.28.54-21.41c.16-3.57.36-7.14.64-10.7s.62-7.13 1.26-10.68c.16-.89.36-1.77.61-2.65a10.19 10.19 0 011.09-2.57 35.19 35.19 0 013.27-4.34c2.35-2.71 4.85-5.27 7.34-7.83 5-5.1 10.09-10.09 14.79-15.43 1.17-1.34 2.3-2.71 3.34-4.14a19.4 19.4 0 002.62-4.56 36.84 36.84 0 001.92-10.4 118.09 118.09 0 00-.84-21.34z"
          style={{
            WebkitTransformOrigin: 407.90785026550293,
            MsTransformOrigin: 407.90785026550293,
            transformOrigin: 407.90785026550293,
          }}
          id="el20ug4wwliva"
          fill="#37474F"
          className="animable"
        ></path>
        <path
          d="M361.54 249.17s67.26 3.83 67.64 3.29-11.75-81.46-35.44-91.14c-4.65-1.91-12.32-1.78-17.21 2.16-14.47 11.64-16.17 84.58-14.99 85.69z"
          style={{
            WebkitTransformOrigin: 395.211971282959,
            MsTransformOrigin: 395.211971282959,
            transformOrigin: 395.211971282959,
          }}
          id="elzko8rn96pke"
          fill="#EBEBEB"
          className="animable"
        ></path>
        <path
          d="M392.36 160.52L388.9 203l10 74.82 40-3.57s-21.84-82.33-29.55-96c-8.68-15.48-16.99-17.73-16.99-17.73z"
          style={{
            WebkitTransformOrigin: 413.8999938964844,
            MsTransformOrigin: 413.8999938964844,
            transformOrigin: 413.8999938964844,
          }}
          id="elpr0omh5k27"
          fill="#256192"
          className="animable"
        ></path>
        <path
          d="M374.32 163.9s-6.41 38.39-6.14 39.16-5.18 69.87-5.18 69.87l-16-13.66s8.85-19.11 9-20.77 9.74-49.73 12.11-59.6 6.21-15 6.21-15z"
          style={{
            WebkitTransformOrigin: 360.6600036621094,
            MsTransformOrigin: 360.6600036621094,
            transformOrigin: 360.6600036621094,
          }}
          id="ellhq6hf5lb9"
          fill="#256192"
          className="animable"
        ></path>
        <path
          d="M401.23 165.71c23.51 20.11 46 54.1 45.41 72.56-.58 18.22-6.86 16.19-8.65 16-11-1.18-34.77-53-41.79-73.95s-.78-19.59 5.03-14.61z"
          style={{
            WebkitTransformOrigin: 419.8683490753174,
            MsTransformOrigin: 419.8683490753174,
            transformOrigin: 419.8683490753174,
          }}
          id="el2dcqq7b0eeu"
          fill="#256192"
          className="animable"
        ></path>
        <path
          d="M424 239.42c-1.18-2.35-2.4-4.67-3.54-7l-3.5-7c-2.31-4.71-4.66-9.4-6.89-14.15s-4.54-9.45-6.75-14.21c-1.1-2.37-2.19-4.76-3.23-7.17S398 185 397 182.55a141.94 141.94 0 005.42 14.82c2 4.85 4.25 9.61 6.49 14.37s4.67 9.42 7.17 14 5.04 9.26 7.92 13.68z"
          style={{
            WebkitTransformOrigin: 410.5,
            MsTransformOrigin: 410.5,
            transformOrigin: 410.5,
          }}
          id="el2nwe86h296u"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M399.29 188.77a22.16 22.16 0 00-1.4-2.44c-.26-.39-.5-.78-.78-1.16s-.53-.77-.84-1.14a7.12 7.12 0 001.08 2.64 7.9 7.9 0 001.94 2.1z"
          style={{
            WebkitTransformOrigin: 397.780029296875,
            MsTransformOrigin: 397.780029296875,
            transformOrigin: 397.780029296875,
          }}
          id="el9hmlyrbzceo"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M344.18 254.6c7-5.46 3.48-21.5-.93-48.86-.77-4.8-32.7-13.2-33.29-8-.74 6.5 3.93 33.44 7 40.86 6.04 14.6 19.04 22.4 27.22 16z"
          style={{
            WebkitTransformOrigin: 329.0150852203369,
            MsTransformOrigin: 329.0150852203369,
            transformOrigin: 329.0150852203369,
          }}
          id="elh811vnef1pu"
          fill="#AD6359"
          className="animable"
        ></path>
        <path
          d="M312.46 209.89c-3.15-9.95-3.53-22.06-2.13-26.15s12.18-16.06 15.17-18.26 17.41.51 20.65 1.24c2.59.59 4.4 3.27-.53 5.64-6.33 3-13.19 1.12-14.73 2.45s-3.1 8.49-1.1 10.73c1.19 1.31 5.2 1.86 7.59-.27s2.39-5.75 4.77-9.34 8.08-5 8.87-3.6-.84 2.7-2.11 8.74c-.81 3.88.51 9.77-1.91 16.77a84.91 84.91 0 01-4.95 10.08z"
          style={{
            WebkitTransformOrigin: 330.3918991088867,
            MsTransformOrigin: 330.3918991088867,
            transformOrigin: 330.3918991088867,
          }}
          id="el7wub5iueoca"
          fill="#AD6359"
          className="animable"
        ></path>
        <path
          d="M316.79 189.8c-.8-5-.69-15.52 2.75-22.12 2.33-4.47 10.89-15.22 14.11-13.17 4.6 2.92-4.91 17.67-5.5 19.72-1.82 6.29.67 14.52.67 14.52z"
          style={{
            WebkitTransformOrigin: 325.64134407043457,
            MsTransformOrigin: 325.64134407043457,
            transformOrigin: 325.64134407043457,
          }}
          id="elws1q0c4iak"
          fill="#AD6359"
          className="animable"
        ></path>
        <path
          d="M327.55 181.37a18.65 18.65 0 01-.32-4.48 12.56 12.56 0 01.33-2.27 11.24 11.24 0 01.81-2.15c.65-1.36 1.36-2.66 2.12-3.94s1.56-2.52 2.34-3.79a33.15 33.15 0 01-1.67 4.14l-1.89 4A13.27 13.27 0 00328 177c-.19 1.41-.31 2.88-.45 4.37z"
          style={{
            WebkitTransformOrigin: 330.01858401298523,
            MsTransformOrigin: 330.01858401298523,
            transformOrigin: 330.01858401298523,
          }}
          id="ela2ifs8lr69"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M315.83 190.88c-1.59-4.75-3.2-14.89-.95-21.84 1.52-4.7 8.05-16.49 11.49-15 4.93 2.08-1.86 17.89-2.1 20-.72 6.38 3 13.93 3 13.93z"
          style={{
            WebkitTransformOrigin: 320.96484565734863,
            MsTransformOrigin: 320.96484565734863,
            transformOrigin: 320.96484565734863,
          }}
          id="elv3m7fjl43e"
          fill="#AD6359"
          className="animable"
        ></path>
        <path
          d="M325.07 181.34a16.1 16.1 0 01-1.41-6.37 17.14 17.14 0 01.75-3.21c.28-1 .58-2.07.89-3.09q.9-3.09 1.87-6.15c.62-2 1.3-4.11.75-6.23a6.7 6.7 0 01.49 3.2 20.13 20.13 0 01-.56 3.21c-.47 2.1-1 4.17-1.61 6.24-.28 1-.58 2.06-.89 3.09l-.46 1.54a7 7 0 00-.35 1.47 25.13 25.13 0 00.53 6.3z"
          style={{
            WebkitTransformOrigin: 326.04920172691345,
            MsTransformOrigin: 326.04920172691345,
            transformOrigin: 326.04920172691345,
          }}
          id="el0yfc74tbipf"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M309.67 194.27c-.42-7 .16-17.5 1-20.55s5.4-14.18 9-14.87c4.46-.86 2 8.35 1.12 12.21s.82 9.21.82 9.21z"
          style={{
            WebkitTransformOrigin: 315.8960542678833,
            MsTransformOrigin: 315.8960542678833,
            transformOrigin: 315.8960542678833,
          }}
          id="eltd6jp4kwoxk"
          fill="#AD6359"
          className="animable"
        ></path>
        <path
          d="M320.19 158.8a2.65 2.65 0 011.28.35 2.09 2.09 0 01.86 1.06 6.67 6.67 0 01.3 2.69 28.56 28.56 0 01-.89 5.28 21.53 21.53 0 00-.93 5.09 17.7 17.7 0 00.88 5.07c1 3.33 2.65 6.51 3.41 10a23.93 23.93 0 01-.37 10.61 25.83 25.83 0 00.38-5.26 22.37 22.37 0 00-.69-5.18c-1-3.35-2.62-6.49-3.68-9.93a17.64 17.64 0 01-.85-5.37A19.48 19.48 0 01321 168a29.12 29.12 0 001.14-5.12 6.29 6.29 0 00-.07-2.56 2.17 2.17 0 00-1.88-1.52z"
          style={{
            WebkitTransformOrigin: 322.7076563835144,
            MsTransformOrigin: 322.7076563835144,
            transformOrigin: 322.7076563835144,
          }}
          id="elw1klp79gj1g"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M347 221.32l-38.33-9.43s5 24.49 10.69 32.67 14.37 16.19 25 10.69c7.94-4.12 2.64-33.93 2.64-33.93z"
          style={{
            WebkitTransformOrigin: 328.70122718811035,
            MsTransformOrigin: 328.70122718811035,
            transformOrigin: 328.70122718811035,
          }}
          id="elmegdkr8b3pq"
          fill="#256192"
          className="animable"
        ></path>
        <path
          d="M344.16 228.54c-1.94-.61-4-1.06-5.93-1.55s-4-1-6-1.41c-4-.89-8-1.76-12-2.58l-3.28-.65c-1.18-.22-2.38-.29-3.55-.52a.07.07 0 000 .14c1 .24 1.91.62 2.88.87s2 .51 3 .75q3 .71 6 1.38c4 .89 8 1.77 12 2.54 2.26.43 4.54.93 6.82 1.23a.1.1 0 10.06-.2z"
          style={{
            WebkitTransformOrigin: 328.7822313308716,
            MsTransformOrigin: 328.7822313308716,
            transformOrigin: 328.7822313308716,
          }}
          id="elxffu80me14q"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M345.74 221c.44.05.86.11 1.29.18h.07v.09a62.39 62.39 0 011.71 10.06 44.78 44.78 0 01-.11 10.22c-.25-3.4-.54-6.76-.87-10.12s-.7-6.73-1-10.12l.09.1c-.36-.1-.77-.25-1.18-.41z"
          style={{
            WebkitTransformOrigin: 347.3950551748276,
            MsTransformOrigin: 347.3950551748276,
            transformOrigin: 347.3950551748276,
          }}
          id="eljglo1vxjwd"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M372.66 166.84s0-.05.06 0c.16 3.7.76 12.25 6.37 11.13 2.26-.45 4.19-2.12 5.78-3.73a32.54 32.54 0 004.29-5.47 48.67 48.67 0 003.81-6.84c0-.1.19 0 .15.08-1.11 2.39-2 4.85-3.32 7.13a28.31 28.31 0 01-4.62 6c-1.7 1.65-3.77 3.34-6.17 3.7a4.59 4.59 0 01-5-3 21 21 0 01-1.35-9z"
          style={{
            WebkitTransformOrigin: 382.86738777160645,
            MsTransformOrigin: 382.86738777160645,
            transformOrigin: 382.86738777160645,
          }}
          id="el9ht7sn91g5r"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M378.11 174.57c5.84.69 12.12-7.21 14.09-13.21.13-.41-1.17-4.23-2.53-9-.82-2.87-1.65-6.08-2.21-9.07l-17.89 8a48.34 48.34 0 013.93 11.14 6.07 6.07 0 01.1 1.25v.21c.07 2.47.11 10.16 4.51 10.68z"
          style={{
            WebkitTransformOrigin: 380.8894929885864,
            MsTransformOrigin: 380.8894929885864,
            transformOrigin: 380.8894929885864,
          }}
          id="el19r4pyn6xwj"
          fill="#AD6359"
          className="animable"
        ></path>
        <path
          d="M369.57 151.36a48.66 48.66 0 013.94 11.14 6.71 6.71 0 01.09 1.24 1.65 1.65 0 000 .21 9.47 9.47 0 001.94-.25c11.31-2.62 11.29-16.19 11.06-20z"
          style={{
            WebkitTransformOrigin: 378.11235523223877,
            MsTransformOrigin: 378.11235523223877,
            transformOrigin: 378.11235523223877,
          }}
          id="elje3xt7gcpnl"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M389.88 123.49c2.2 6.71-1 26.3-5.68 30.75-6.84 6.45-18.23 7.29-23.76-1-5.36-8-.79-33.46 4.5-37.53 7.78-5.96 21.69-2.14 24.94 7.78z"
          style={{
            WebkitTransformOrigin: 374.31625747680664,
            MsTransformOrigin: 374.31625747680664,
            transformOrigin: 374.31625747680664,
          }}
          id="elsn44viujz1"
          fill="#AD6359"
          className="animable"
        ></path>
        <path
          d="M370.5 135.91s.09.08.08.13c-.22 1.31-.26 2.85.87 3.45v.07c-1.45-.4-1.38-2.44-.95-3.65z"
          style={{
            WebkitTransformOrigin: 370.83962446451187,
            MsTransformOrigin: 370.83962446451187,
            transformOrigin: 370.83962446451187,
          }}
          id="elo6g2tusyvc"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M371.86 134.7c2.14.14 1.71 4.4-.27 4.28s-1.52-4.39.27-4.28z"
          style={{
            WebkitTransformOrigin: 371.7895932197571,
            MsTransformOrigin: 371.7895932197571,
            transformOrigin: 371.7895932197571,
          }}
          id="elwgp9zbfs1s"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M362.74 135.12c0-.05-.11.06-.11.11-.09 1.33-.42 2.83-1.66 3.15v.07c1.48-.06 1.9-2.06 1.77-3.33z"
          style={{
            WebkitTransformOrigin: 361.86612981557846,
            MsTransformOrigin: 361.86612981557846,
            transformOrigin: 361.86612981557846,
          }}
          id="elaadh53v82of"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M361.7 133.63c-2.11-.38-2.7 3.87-.75 4.21s2.52-3.9.75-4.21z"
          style={{
            WebkitTransformOrigin: 361.26243138313293,
            MsTransformOrigin: 361.26243138313293,
            transformOrigin: 361.26243138313293,
          }}
          id="el8b9novdzcty"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M373 132.26a15.46 15.46 0 01-1.6-.74 3 3 0 01-1.48-1.07.91.91 0 01.26-1.08 2.25 2.25 0 012.27 0 3.28 3.28 0 011.75 1.61 1 1 0 01-1.2 1.28z"
          style={{
            WebkitTransformOrigin: 372.04527831077576,
            MsTransformOrigin: 372.04527831077576,
            transformOrigin: 372.04527831077576,
          }}
          id="el5i28xstf3f8"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M360.27 131.32c.6 0 1.14-.18 1.73-.27a2.91 2.91 0 001.72-.62.89.89 0 00.06-1.1 2.24 2.24 0 00-2.18-.65 3.28 3.28 0 00-2.13 1.06 1 1 0 00.8 1.58z"
          style={{
            WebkitTransformOrigin: 361.61323976516724,
            MsTransformOrigin: 361.61323976516724,
            transformOrigin: 361.61323976516724,
          }}
          id="elvbqd9ash35"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M370.73 147.72c-.29.34-.56.8-1 .88a3.42 3.42 0 01-1.45-.19c-.05 0-.08 0-.05.07a1.8 1.8 0 001.74.56 1.41 1.41 0 00.93-1.27.07.07 0 00-.17-.05z"
          style={{
            WebkitTransformOrigin: 369.560840010643,
            MsTransformOrigin: 369.560840010643,
            transformOrigin: 369.560840010643,
          }}
          id="elu3topf8ow"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M369.44 143.76a4.51 4.51 0 01-3.2 1.7 5.89 5.89 0 01-1.62-.09 5.63 5.63 0 01-.59-.14.32.32 0 01-.24-.32c0-.05.37-2.82.37-2.82-.39.15-2.37.82-2.29.29.71-4.37 1.49-9.24 3.11-13.44a.12.12 0 01.24.06c-.51 4.23-1.76 8.34-2.34 12.58a7.7 7.7 0 012.19-.43c.07 0-.54 3.44-.54 3.47a6.26 6.26 0 004.8-1c.11-.06.2.04.11.14z"
          style={{
            WebkitTransformOrigin: 365.6722221374512,
            MsTransformOrigin: 365.6722221374512,
            transformOrigin: 365.6722221374512,
          }}
          id="elkc9bgpf1wgc"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M365.29 144.84a5.22 5.22 0 002 1.83 2.38 2.38 0 001.43.23c1.07-.18 1.19-1.14 1.07-2a5.59 5.59 0 00-.35-1.3 6.9 6.9 0 01-4.15 1.24z"
          style={{
            WebkitTransformOrigin: 367.5587866306305,
            MsTransformOrigin: 367.5587866306305,
            transformOrigin: 367.5587866306305,
          }}
          id="elhdy2842grag"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M367.26 146.67a2.38 2.38 0 001.43.23c1.07-.18 1.19-1.14 1.07-2a2.46 2.46 0 00-2.5 1.77z"
          style={{
            WebkitTransformOrigin: 368.5287878513336,
            MsTransformOrigin: 368.5287878513336,
            transformOrigin: 368.5287878513336,
          }}
          id="elf3pztdz5u9"
          fill="#FF9CBD"
          className="animable"
        ></path>
        <path
          d="M390.77 141.06c-5.25.1-7.66-6.27-6.18-11.22 0 0-14 1.14-14.1-10.06a12.66 12.66 0 01-9.91 4.83l-1.11 4c-1.4-.49-2.76-4.89.89-7.89 0 0 .52-5.56 4.79-8.69a7.62 7.62 0 018.24-.73 11.5 11.5 0 018.49-1.64 9.8 9.8 0 017 5.69s6.82 1.66 7.75 6.61a10.2 10.2 0 01-1.77 8.11s1.63 2 .54 6.14c-.74 2.69-3.05 4.79-4.63 4.85z"
          style={{
            WebkitTransformOrigin: 377.42959213256836,
            MsTransformOrigin: 377.42959213256836,
            transformOrigin: 377.42959213256836,
          }}
          id="elq62lq0x69dm"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M370.84 118.81a13.08 13.08 0 00-.16 2.72 10.74 10.74 0 00.37 2.66 7 7 0 003 4.17 11.35 11.35 0 005.06 1.55c.91 0 1.82.1 2.72.07s1.82-.12 2.75-.14c-.9.17-1.81.3-2.73.45s-1.84.07-2.77.11a11.21 11.21 0 01-5.38-1.5 7.35 7.35 0 01-3.14-4.59 11.12 11.12 0 01.28-5.5z"
          style={{
            WebkitTransformOrigin: 377.45984840393066,
            MsTransformOrigin: 377.45984840393066,
            transformOrigin: 377.45984840393066,
          }}
          id="elkz4zsz9l3un"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M388.84 115.39a12.82 12.82 0 015.07 1.37 7.75 7.75 0 013.7 3.81 8.39 8.39 0 01.53 2.65 8 8 0 01-.27 2.67 7.22 7.22 0 01-3.05 4.22 7.89 7.89 0 002.57-4.34 8.82 8.82 0 00-.38-5 6.57 6.57 0 00-1.34-2.08 9.77 9.77 0 00-2-1.54 16.24 16.24 0 00-4.83-1.76z"
          style={{
            WebkitTransformOrigin: 393.49917793273926,
            MsTransformOrigin: 393.49917793273926,
            transformOrigin: 393.49917793273926,
          }}
          id="elru0nepzssnl"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M373.39 111.34a13.15 13.15 0 00-4.05-1 7.47 7.47 0 00-7 3.32 13.89 13.89 0 00-1.85 3.74 10.34 10.34 0 011.44-4 7.16 7.16 0 017.47-3.53 9.9 9.9 0 013.99 1.47z"
          style={{
            WebkitTransformOrigin: 366.9400177001953,
            MsTransformOrigin: 366.9400177001953,
            transformOrigin: 366.9400177001953,
          }}
          id="el760y90tzhti"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M363.35 124.9a14.54 14.54 0 004.33-2.06 8.8 8.8 0 001.72-1.62 11.4 11.4 0 001.27-2 5.89 5.89 0 01-.87 2.34 6.53 6.53 0 01-1.74 1.85 8.09 8.09 0 01-2.25 1.14 7.68 7.68 0 01-2.46.35z"
          style={{
            WebkitTransformOrigin: 367.00999450683594,
            MsTransformOrigin: 367.00999450683594,
            transformOrigin: 367.00999450683594,
          }}
          id="elapgol2dwzki"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M371 143.27a5.86 5.86 0 01-4.17-7.13 5.84 5.84 0 115.61 7.32 6.55 6.55 0 01-1.44-.19zm-3.41-6.93a5.05 5.05 0 106.17-3.61 5.44 5.44 0 00-1.25-.17 5.08 5.08 0 00-4.91 3.78z"
          style={{
            WebkitTransformOrigin: 372.4809160232544,
            MsTransformOrigin: 372.4809160232544,
            transformOrigin: 372.4809160232544,
          }}
          id="eljg0bxpc39va"
          fill="#455A64"
          className="animable"
        ></path>
        <path
          d="M359.29 141.71c-2.37-.29-4-3.13-3.59-6.32a6.94 6.94 0 011.73-3.9 3.63 3.63 0 016.17.77 6.91 6.91 0 01.72 4.2c-.37 3-2.41 5.29-4.65 5.28zM358 132a6.16 6.16 0 00-1.53 3.46c-.34 2.77 1 5.22 2.92 5.46s3.82-1.81 4.16-4.58a6.24 6.24 0 00-.63-3.73 3.09 3.09 0 00-2.29-1.72 1.37 1.37 0 00-.29 0A3.19 3.19 0 00358 132z"
          style={{
            WebkitTransformOrigin: 360.0139989852905,
            MsTransformOrigin: 360.0139989852905,
            transformOrigin: 360.0139989852905,
          }}
          id="elyb019yqostc"
          fill="#455A64"
          className="animable"
        ></path>
        <path
          d="M366.9 136.83l.48-.45c-.66-.71-2.27-1.76-3.62-.63l.42.5c1.21-1.01 2.66.51 2.72.58z"
          style={{
            WebkitTransformOrigin: 365.57000732421875,
            MsTransformOrigin: 365.57000732421875,
            transformOrigin: 365.57000732421875,
          }}
          id="elq7z5ohvtb2"
          fill="#455A64"
          className="animable"
        ></path>
        <path
          d="M393.66 142.21l.62-.23c-.11-.28-1.08-2.82-2.51-3.24s-13.54-1.84-13.92-1.85v.65c2.54.08 12.73 1.53 13.76 1.83s1.8 2.15 2.05 2.84z"
          style={{
            WebkitTransformOrigin: 386.0649871826172,
            MsTransformOrigin: 386.0649871826172,
            transformOrigin: 386.0649871826172,
          }}
          id="el4hv56nfujw1"
          fill="#455A64"
          className="animable"
        ></path>
        <path
          d="M385.28 140.67s4.41-6.31 7.28-4.74-.45 10.72-3.81 12a3.43 3.43 0 01-4.59-1.84z"
          style={{
            WebkitTransformOrigin: 388.9060459136963,
            MsTransformOrigin: 388.9060459136963,
            transformOrigin: 388.9060459136963,
          }}
          id="el49t254orqs6"
          fill="#AD6359"
          className="animable"
        ></path>
        <path
          d="M391.54 138.53v.09c-2.36 1-3.6 3.14-4.47 5.41a1.9 1.9 0 012.83-.52c.06.05 0 .14-.07.13a2.1 2.1 0 00-2.31.73 10.1 10.1 0 00-1.11 1.88c-.11.21-.49.1-.41-.15 0-3.1 2.31-7.27 5.54-7.57z"
          style={{
            WebkitTransformOrigin: 388.7645330429077,
            MsTransformOrigin: 388.7645330429077,
            transformOrigin: 388.7645330429077,
          }}
          id="el53y9nbwanxp"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M373.41 161.7l5.51 10.51 12.2-15.21 4.31 4.05s-11.08 23.37-11.27 22.39l-5.47-9-5.85 7.23-1.14-16.54z"
          style={{
            WebkitTransformOrigin: 383.5649871826172,
            MsTransformOrigin: 383.5649871826172,
            transformOrigin: 383.5649871826172,
          }}
          id="elmvl7mg1cqcl"
          fill="#EBEBEB"
          className="animable"
        ></path>
        <path
          d="M371.81 173.51c-.09-2.74-.27-5.5-.24-8.24 0-.09.15-.15.18 0 .81 2.57 1.13 13 1.39 15.53.24-.31 5.45-6.39 5.52-6.31 1.13 1.4 5.14 7.94 5.43 8.55 1.75-3.72 8.53-17.25 10.69-20.81a.09.09 0 01.17.08c-1.48 3.88-10.43 22.09-10.59 22a106 106 0 01-5.74-9.5c-.85 1.3-6 8.09-6.11 7.69a87 87 0 01-.7-8.99z"
          style={{
            WebkitTransformOrigin: 383.26263999938965,
            MsTransformOrigin: 383.26263999938965,
            transformOrigin: 383.26263999938965,
          }}
          id="el6tvvnt3f6b"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M440.52 254.33c-8.19.78-18.08-6-43.59-26.29-3.54-2.81 9.6-33.07 13.53-30.26 4.94 3.54 23.36 18.34 24.57 19.59 10.21 10.56 11.04 36.43 5.49 36.96z"
          style={{
            WebkitTransformOrigin: 420.1283302307129,
            MsTransformOrigin: 420.1283302307129,
            transformOrigin: 420.1283302307129,
          }}
          id="elxwqp3vbzjbk"
          fill="#AD6359"
          className="animable"
        ></path>
        <path
          d="M419.47 207.48s-7.06-10.84-12.47-15.38c-7.33-6.09-16.15-8.32-19.91-9-7.84-1.49-27.83.32-28.31 6.17-.44 5.29 19.9 2.41 19.9 2.41s-21.22-.48-20.57 6 24.06 1.78 24.06 1.78-21.81 0-20.09 6.56c1.3 5 22.75 1.91 22.75 1.91s-18.75.12-17.63 5.29c1.23 5.7 21.21 4.12 21.21 4.12 5.05 12.64 12.89 16.47 18.62 18.64 1.33.48 12.44-28.5 12.44-28.5z"
          style={{
            WebkitTransformOrigin: 388.7826614379883,
            MsTransformOrigin: 388.7826614379883,
            transformOrigin: 388.7826614379883,
          }}
          id="elqmzbav3s23"
          fill="#AD6359"
          className="animable"
        ></path>
        <path
          d="M386 191.33c-2.49-.14-5-.31-7.51-.23s-5 .24-7.46.54a42.72 42.72 0 00-9.53 2.12c5.61-1.49 14.54-1.9 17-2s5-.27 7.47-.35c.08.02.03-.07.03-.08z"
          style={{
            WebkitTransformOrigin: 373.75772857666016,
            MsTransformOrigin: 373.75772857666016,
            transformOrigin: 373.75772857666016,
          }}
          id="elnk8ug00n3x8"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M386.42 200.34c-3.19-.05-6.36-.22-9.55-.12s-6.18.45-9.34.76c-.24 0-.15.15.09.13 3.18-.18 6.12-.1 9.29-.25s6.35-.41 9.51-.44c.05-.01.04-.08 0-.08z"
          style={{
            WebkitTransformOrigin: 376.92136001586914,
            MsTransformOrigin: 376.92136001586914,
            transformOrigin: 376.92136001586914,
          }}
          id="el5qrs837wyp4"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M388.18 208a71.54 71.54 0 00-14.61.93c-.24 0-.1.18.15.16 4.85-.4 8.65-.75 14.45-.92.09 0 .11-.17.01-.17z"
          style={{
            WebkitTransformOrigin: 380.84632873535156,
            MsTransformOrigin: 380.84632873535156,
            transformOrigin: 380.84632873535156,
          }}
          id="elpxb189n2vie"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M419.64 203.3l-11.95 37.36s27.14 17.55 33.47 13.47 3.22-26.07-3.39-34.68c-2.77-3.59-18.13-16.15-18.13-16.15z"
          style={{
            WebkitTransformOrigin: 426.15137481689453,
            MsTransformOrigin: 426.15137481689453,
            transformOrigin: 426.15137481689453,
          }}
          id="elwf4fbuune98"
          fill="#256192"
          className="animable"
        ></path>
        <path
          d="M419.25 204.54l.46-1.22h-.11c2.54 2.25 5.09 4.49 7.6 6.77s5 4.54 7.41 6.93a39.4 39.4 0 013.38 3.72 29.47 29.47 0 012.63 4.25 32.07 32.07 0 013 9.63 26.74 26.74 0 00-2.31-9.93 23.75 23.75 0 00-2.59-4.47 38.14 38.14 0 00-3.4-3.86c-2.43-2.4-5-4.66-7.57-6.86s-5.28-4.28-8-6.29l-.08-.05v.09z"
          style={{
            WebkitTransformOrigin: 431.4350128173828,
            MsTransformOrigin: 431.4350128173828,
            transformOrigin: 431.4350128173828,
          }}
          id="el96jw3y3v4ev"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M425.35 212.93c-.71 1.83-1.28 3.73-1.88 5.6s-1.19 3.74-1.75 5.63c-1.12 3.76-2.23 7.53-3.28 11.32-.29 1-.58 2.07-.85 3.11s-.43 2.27-.73 3.38c0 .09.1.12.13 0 .29-.92.72-1.8 1-2.72s.62-1.9.92-2.86q.87-2.82 1.72-5.64c1.13-3.76 2.24-7.54 3.24-11.33.57-2.15 1.2-4.3 1.64-6.47a.1.1 0 00-.16-.02z"
          style={{
            WebkitTransformOrigin: 421.18499755859375,
            MsTransformOrigin: 421.18499755859375,
            transformOrigin: 421.18499755859375,
          }}
          id="elev4585rg5wv"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M408.75 237.33c-.27 1.14-.58 2.26-.88 3.38l-.07-.21c2.33 1.42 4.7 2.8 7.07 4.15s4.77 2.68 7.19 3.94c1.2.64 2.44 1.22 3.65 1.84s2.47 1.16 3.72 1.68a39.24 39.24 0 007.77 2.54 28.54 28.54 0 01-8-1.87 61.22 61.22 0 01-7.56-3.32c-2.46-1.24-4.85-2.6-7.19-4.05s-4.62-3-6.84-4.6l-.12-.09.05-.12c.38-1.09.78-2.19 1.21-3.27z"
          style={{
            WebkitTransformOrigin: 422.34498596191406,
            MsTransformOrigin: 422.34498596191406,
            transformOrigin: 422.34498596191406,
          }}
          id="eltxz9fjq093"
          fill="#263238"
          className="animable"
        ></path>
      </g>
      <g
        id="freepik--character-1--inject-45"
        style={{
          WebkitTransformOrigin: 172.6843490600586,
          MsTransformOrigin: 172.6843490600586,
          transformOrigin: 172.6843490600586,
        }}
        className="animable"
      >
        <path
          style={{
            WebkitTransformOrigin: 192.06000518798828,
            MsTransformOrigin: 192.06000518798828,
            transformOrigin: 192.06000518798828,
          }}
          id="elc2qv4flfa0j"
          fill="#DBDBDB"
          d="M178.63 315.72L181.66 333.89 202.18 457.53 205.49 457.53 190.57 333.89 188.36 315.72 178.63 315.72z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 184.60000610351562,
            MsTransformOrigin: 184.60000610351562,
            transformOrigin: 184.60000610351562,
          }}
          id="elzsc700e10y"
          fill="#A6A6A6"
          d="M188.36 315.72L178.63 315.72 181.66 333.89 190.57 333.89 188.36 315.72z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 127.59500122070312,
            MsTransformOrigin: 127.59500122070312,
            transformOrigin: 127.59500122070312,
          }}
          id="elilmun8zq1rc"
          fill="#DBDBDB"
          d="M114.16 457.53L117.47 457.53 138 333.89 141.03 315.72 131.29 315.72 129.09 333.89 114.16 457.53z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 135.05999755859375,
            MsTransformOrigin: 135.05999755859375,
            transformOrigin: 135.05999755859375,
          }}
          id="elj2jgkz811xr"
          fill="#A6A6A6"
          d="M131.29 315.72L129.09 333.89 138 333.89 141.03 315.72 131.29 315.72z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 66.01499938964844,
            MsTransformOrigin: 66.01499938964844,
            transformOrigin: 66.01499938964844,
          }}
          id="elkxrg5hpkhoo"
          fill="#DBDBDB"
          d="M52.58 457.53L55.89 457.53 76.42 333.89 79.45 315.72 69.72 315.72 67.51 333.89 52.58 457.53z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 73.47999954223633,
            MsTransformOrigin: 73.47999954223633,
            transformOrigin: 73.47999954223633,
          }}
          id="el98w6ibqx5at"
          fill="#A6A6A6"
          d="M67.51 333.89L76.42 333.89 79.45 315.72 69.72 315.72 67.51 333.89z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 133.0449981689453,
            MsTransformOrigin: 133.0449981689453,
            transformOrigin: 133.0449981689453,
          }}
          id="eltoer9d7ukrm"
          fill="#DBDBDB"
          d="M57.83 324.26L208.26 324.26 208.26 308.41 151.95 308.41 133.02 198.3 63.57 198.3 57.83 324.26z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 64.60499954223633,
            MsTransformOrigin: 64.60499954223633,
            transformOrigin: 64.60499954223633,
          }}
          id="elq528lxofyed"
          fill="#A6A6A6"
          d="M57.83 324.26L66.03 324.26 71.38 198.3 63.57 198.3 57.83 324.26z"
          className="animable"
        ></path>
        <path
          d="M292 413.64c-2.49 1.6-80.35 21.43-83.8 20.31-1.31-.42-4.85-11.59-8.76-25.21-.84-2.94-1.7-6-2.55-9.08-5.51-20.08-10.89-41.52-10.89-41.52l52-13.42 9.51 41.89 2.09 9.2s33.21 5.74 36.86 7.37 8.05 8.82 5.54 10.46z"
          style={{
            WebkitTransformOrigin: 239.36923217773438,
            MsTransformOrigin: 239.36923217773438,
            transformOrigin: 239.36923217773438,
          }}
          id="elgwjxxn5ktc7"
          fill="#F7A9A0"
          className="animable"
        ></path>
        <path
          d="M201 413.93c3.29 11.17 6.12 19.65 7.26 20 3.45 1.12 81.31-18.72 83.8-20.32s-1.91-8.8-5.54-10.45c-2.25-1-15.75-3.57-25.77-5.4 8.75 1.7 25.17 6.87-8.15 10.73-27.6 3.23-45.78 4.93-51.6 5.44z"
          style={{
            WebkitTransformOrigin: 246.89435195922852,
            MsTransformOrigin: 246.89435195922852,
            transformOrigin: 246.89435195922852,
          }}
          id="elhxdk7etceag"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M222.85 456.89c-2.82.92-83.16.25-86.21-1.71-1.16-.74.53-33.06 1.24-36.19 2.39-10.61 14.57-42.77 14.57-42.77l53.74.29-19.8 52.34s30.65 14 33.76 16.52 5.51 10.63 2.7 11.52z"
          style={{
            WebkitTransformOrigin: 180.1698112487793,
            MsTransformOrigin: 180.1698112487793,
            transformOrigin: 180.1698112487793,
          }}
          id="el255nryba80z"
          fill="#F7A9A0"
          className="animable"
        ></path>
        <path
          d="M136.64 455.18c3.05 2 83.39 2.63 86.21 1.72s.4-9-2.69-11.53c-1.46-1.17-8.88-4.82-16.39-8.4 9.44 4.79 24.53 15.73-67.12-1-.43 9.25-.65 18.81-.01 19.21z"
          style={{
            WebkitTransformOrigin: 180.16864776611328,
            MsTransformOrigin: 180.16864776611328,
            transformOrigin: 180.16864776611328,
          }}
          id="el4kvbl8paiia"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M91.62 303.73c16.74 17.11 69.82.73 73.33 7.67 3.1 6.12-29.18 108-29.18 108l58.89 3.78S240.47 310.23 221 279c-10.52-16.86-53.27-8.11-79.39-10.14-7.88-.62-12.26-16.62-12.26-16.62l-46.43 2s-10.45 29.94 8.7 49.49z"
          style={{
            WebkitTransformOrigin: 152.94686889648438,
            MsTransformOrigin: 152.94686889648438,
            transformOrigin: 152.94686889648438,
          }}
          id="elqhfujr6y91j"
          fill="#37474F"
          className="animable"
        ></path>
        <path
          d="M204.26 269.79l-47.59 39.8c-16.36.36-51.9 7.58-65-5.86-19.15-19.55-8.71-49.48-8.71-49.48l46.43-2s4.38 16 12.25 16.62c18.29 1.41 44.79-2.47 62.62.92z"
          style={{
            WebkitTransformOrigin: 142.18976211547852,
            MsTransformOrigin: 142.18976211547852,
            transformOrigin: 142.18976211547852,
          }}
          id="el25hukfc2tfh"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M196.33 410c-1.64-.43-16.91-1.47-20.28-1.69-6.74-.43-28-1.49-31.78-1.31-.1 0-.11.16 0 .18 3.36.55 16.91 1.54 20.27 1.74 6.75.4 29.93 1.64 31.79 1.37a.15.15 0 000-.29z"
          style={{
            WebkitTransformOrigin: 170.31637573242188,
            MsTransformOrigin: 170.31637573242188,
            transformOrigin: 170.31637573242188,
          }}
          id="eltv7ofel1z2h"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M171.81 305.22c-3.85-1.71-32.38.45-53-.58-6.7-.34-13.47-1.47-18.75-5.63a30.45 30.45 0 01-9.78-14.63C88 277.46 87.92 270.15 88 263c0-1.76 0-3.53.08-5.3 0-.1-.18-.12-.18 0-1 12.92-1.58 27.33 7.26 38.1a26.87 26.87 0 0015.37 9.16c12.51 2.71 59-.11 60.66 1.08 2 1.41-24.07 94.21-24.59 96-.1.33.48.53.6.2 2.61-7.24 27.96-95.53 24.61-97.02z"
          style={{
            WebkitTransformOrigin: 129.73493194580078,
            MsTransformOrigin: 129.73493194580078,
            transformOrigin: 129.73493194580078,
          }}
          id="eladd96zl4gtk"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M223.23 282c-3.07-6.9-9.57-11-18.91-12.27-.16 0-.14.21 0 .25 8.2 2 14.13 5 17.6 11.71 1.63 3.12 2.59 7.57 3.24 13.56 3.22 29.88-20 99-20.46 100.22 0 .11.11.19.16.08 3.43-6.35 31.03-85.08 18.37-113.55z"
          style={{
            WebkitTransformOrigin: 215.36897087097168,
            MsTransformOrigin: 215.36897087097168,
            transformOrigin: 215.36897087097168,
          }}
          id="eloa3sofpimok"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M248.87 383.86l-44 11.37s30.5-106 13.46-118.81c-4.72-3.54-14.71-4.42-26.31 1.2-17.3 8.38-37 24.8-56 31.31-17.8 6.1-39.41 2.13-47.12-5.65-16.21-16.36-6-49-6-49l63.74-5.16s-.92.81 2.24 3.38c0 0 37.15-10.13 53.89-.88 30.23 16.65 46.1 132.24 46.1 132.24z"
          style={{
            WebkitTransformOrigin: 164.29852294921875,
            MsTransformOrigin: 164.29852294921875,
            transformOrigin: 164.29852294921875,
          }}
          id="elyyka1v18yif"
          fill="#37474F"
          className="animable"
        ></path>
        <path
          d="M110.65 256.77a.11.11 0 00-.22.05 35.9 35.9 0 01-.24 7.18 12.43 12.43 0 01-3 6.09c-3.35 3.69-8.32 5.11-12.84 6.76-.24.08-.18.51.09.46 5.06-1 10.35-2.53 13.91-6.51a12.37 12.37 0 003-6.55 15.24 15.24 0 00-.7-7.48z"
          style={{
            WebkitTransformOrigin: 102.86705017089844,
            MsTransformOrigin: 102.86705017089844,
            transformOrigin: 102.86705017089844,
          }}
          id="elfcy38fsyiz"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M217.16 274.47c-7-4-17.53-.27-22.56 1.91-.1 0 0 .21.06.17 4.63-1.89 16.21-5 22.65-.8 14.89 9.6-1.41 80.42-12.42 119.48-.17.61.3.43.54-.16 1.9-4.66 16.12-54 19-86.41.43-4.98 2.84-28.44-7.27-34.19z"
          style={{
            WebkitTransformOrigin: 209.7726764678955,
            MsTransformOrigin: 209.7726764678955,
            transformOrigin: 209.7726764678955,
          }}
          id="elbpq3ipyyt8c"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M243.3 372.28c-1.34 0-12.74 3.06-15.24 3.74-5 1.36-16.64 4.91-19.34 6.08-.07 0 0 .18.06.17 2.62-.37 8.69-2 11.19-2.67 5-1.4 22.18-6.31 23.43-7.05.1-.06.03-.27-.1-.27z"
          style={{
            WebkitTransformOrigin: 226.06908226013184,
            MsTransformOrigin: 226.06908226013184,
            transformOrigin: 226.06908226013184,
          }}
          id="el0uttswr7xw9"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M214.91 272.75c-4.7-2.31-10.18-2.49-15.25-1.64a53.12 53.12 0 00-15.28 5.52c-10.18 5.2-19.82 11.43-30 16.6s-21 9.3-32.39 10.14c-9.72.72-22.12-.46-28.76-8.95-10.24-13.08-5.62-36.59-5.58-38.1 0-.12-.15-.11-.17 0-6.25 28.93 3.86 39.62 10.56 44 5.32 3.48 12.78 4.1 17.7 4.3 11.37.47 22.61-2.8 32.93-7.44 10.59-4.76 20.43-11 30.52-16.69 9.67-5.49 20.46-11.18 31.88-8.77a28.31 28.31 0 013.82 1.12c.05.04.11-.06.02-.09z"
          style={{
            WebkitTransformOrigin: 150.2381820678711,
            MsTransformOrigin: 150.2381820678711,
            transformOrigin: 150.2381820678711,
          }}
          id="elzj2y3pofc9c"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M141.11 171.12c.69 4.13 1.39 8.28 1.92 12.44.32 2.54.48 5.06.57 7.6a16.32 16.32 0 01-2.58 2.73c0-.64-.05-1.29-.07-1.91-.1-3-.44-6-.82-9 0-.1-.15-.06-.14 0 .29 3.1.36 6.26.41 9.38v2a22.12 22.12 0 01-2.88 1.9v-2.22c0-1.73 0-3.45-.12-5.17a97.45 97.45 0 00-1-10.12c0-.14-.19-.09-.18 0 .34 3.47.56 7 .68 10.46 0 1.65.08 3.31.08 5 0 .79 0 1.59-.06 2.39a35.33 35.33 0 01-5.71 2.34c-5.6 1.77-11.72 1.45-17.43.37A30.28 30.28 0 01100 193a33.48 33.48 0 003-8.48c0-.1-.09-.14-.13-.05-.67 1.89-1.41 3.73-2.22 5.54-.39.85-.81 1.68-1.22 2.51l-.18-.16c-.39-.35-.8-.72-1.21-1.12a55 55 0 002.09-5.3 48.14 48.14 0 002.12-7.81c0-.17-.17-.25-.23-.08-.85 2.48-1.64 5-2.52 7.47-.62 1.74-1.34 3.44-2 5.16a7 7 0 01-2.14-4.76 13.52 13.52 0 011.21-4.5 51 51 0 001.56-4.91c.93-3.43 3.8-8.3 4.76-22.41.22-3.32 0-6.67.26-10a12.46 12.46 0 012.87-7.47 11.89 11.89 0 015.4-3.3c0-.09 10.32-11.09 22.67-4.18 14 7.85 14.33 48.4 13.49 53.27a25.05 25.05 0 01-3.24 7.7c-.09-2.14-.32-4.28-.59-6.36-.55-4.3-1.47-8.5-2.41-12.71a.1.1 0 10-.23.07z"
          style={{
            WebkitTransformOrigin: 121.6035213470459,
            MsTransformOrigin: 121.6035213470459,
            transformOrigin: 121.6035213470459,
          }}
          id="elovhdjd08hog"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M123.14 190.83c14.89 16.76 34.92 32.33 43 31.71 6.52-.5 44.59-25.65 47.2-29.33 2.24-3.16-17.17-26.07-20.91-24.27-6.28 3-28.19 22.79-30.13 22.59s-18.17-6.46-34.34-13.22c-15.46-6.48-15.15.88-4.82 12.52z"
          style={{
            WebkitTransformOrigin: 164.6618423461914,
            MsTransformOrigin: 164.6618423461914,
            transformOrigin: 164.6618423461914,
          }}
          id="elho9215qxsxc"
          fill="#F7A9A0"
          className="animable"
        ></path>
        <path
          d="M209.15 196.65c3.06-1.9 16.32-11.87 19.39-15.47s11.15-14.59 8.63-17.22-5.14.4-5.14.4 2.68-5.19-.09-7.6-6.71 2.43-6.71 2.43 2.37-5.53-.57-7.74-6.08 3.32-6.08 3.32 2.4-5-.79-5.75c-3.84-1-7.22 8.66-10.91 12.38-.84.84-18.19 15.9-18.19 15.9z"
          style={{
            WebkitTransformOrigin: 213.16986274719238,
            MsTransformOrigin: 213.16986274719238,
            transformOrigin: 213.16986274719238,
          }}
          id="eli48uw1492ko"
          fill="#F7A9A0"
          className="animable"
        ></path>
        <path
          d="M218.86 154.31c-2.5 7.07-4.82 9.73-10.63 14.3-.11.09 0 .28.12.19 6.09-4 9.48-6.87 10.86-14.39.07-.41-.21-.41-.35-.1z"
          style={{
            WebkitTransformOrigin: 213.70285940170288,
            MsTransformOrigin: 213.70285940170288,
            transformOrigin: 213.70285940170288,
          }}
          id="el1bex1yt2hzu"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M225.42 158.62c-3.24 7.12-5.73 10-11.46 15.26-.08.08 0 .22.13.15 6-4.66 9.36-7.65 11.67-15.19.1-.34-.19-.54-.34-.22z"
          style={{
            WebkitTransformOrigin: 219.85426902770996,
            MsTransformOrigin: 219.85426902770996,
            transformOrigin: 219.85426902770996,
          }}
          id="elxzou9l3fetp"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M231.73 164.62c-3.64 6.66-6.23 10-12.44 14.33a.14.14 0 00.15.24c6.76-3.84 10-7.94 12.48-14.44.17-.45.08-.55-.19-.13z"
          style={{
            WebkitTransformOrigin: 225.61492729187012,
            MsTransformOrigin: 225.61492729187012,
            transformOrigin: 225.61492729187012,
          }}
          id="elf3flj7qlsab"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M182.73 177.53s2.64-9 4.67-12.73 14.7-14.74 17.24-12.14c3.27 3.36-2.78 11.16-6.25 14.6 0 0 7.59 7.76 4.45 14.3s-20.11-4.03-20.11-4.03z"
          style={{
            WebkitTransformOrigin: 194.15441608428955,
            MsTransformOrigin: 194.15441608428955,
            transformOrigin: 194.15441608428955,
          }}
          id="el4uzj293qpt6"
          fill="#F7A9A0"
          className="animable"
        ></path>
        <path
          d="M205.23 153.88c.78 5.21-4.06 9.43-7.17 13a.43.43 0 00.14.69c3 3.47 6.14 9.22 4.92 13.87 0 .14.16.21.21.08 2-4.82-.67-10.72-4.16-14.31 3.25-3.63 7.92-8.07 6.11-13.36a.032.032 0 00-.05.03z"
          style={{
            WebkitTransformOrigin: 201.81301164627075,
            MsTransformOrigin: 201.81301164627075,
            transformOrigin: 201.81301164627075,
          }}
          id="elk1f7jrrxmzp"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M179.58 176.91l25.58 29s-31.49 18.72-39.95 17.51-19.18-8.06-41.11-31.1c-17.37-18.25-4.28-19 6.26-14.25 8.61 3.86 30.44 12.59 32.1 11.76s17.12-12.92 17.12-12.92z"
          style={{
            WebkitTransformOrigin: 160.39995956420898,
            MsTransformOrigin: 160.39995956420898,
            transformOrigin: 160.39995956420898,
          }}
          id="el63ec217b7c5"
          fill="#256192"
          className="animable"
        ></path>
        <path
          d="M205.15 205.91s-28.48 16.94-38.54 17.55a7.07 7.07 0 01-1.4 0c-8.46-1.22-19.18-8.06-41.12-31.1-7.68-8.08-9.4-12.72-8-15 1.75-2.84 8.41-1.91 14.3.73 8.6 3.86 30.43 12.6 32.1 11.76s17.12-12.92 17.12-12.92z"
          style={{
            WebkitTransformOrigin: 160.3984031677246,
            MsTransformOrigin: 160.3984031677246,
            transformOrigin: 160.3984031677246,
          }}
          id="eli3prxhi6g3"
          fill="#256192"
          className="animable"
        ></path>
        <path
          d="M174.67 183c3.49 4.51 7.2 8.87 11 13.14 1.86 2.09 3.72 4.19 5.65 6.21s4.16 4.1 6.16 6.22c.09.09.24 0 .15-.14-1.92-2.16-3.69-4.48-5.57-6.7s-3.68-4.21-5.53-6.31c-3.78-4.28-7.59-8.55-11.51-12.69a.21.21 0 00-.35.27z"
          style={{
            WebkitTransformOrigin: 186.1408519744873,
            MsTransformOrigin: 186.1408519744873,
            transformOrigin: 186.1408519744873,
          }}
          id="el41laoyeyuq3"
          fill="#263238"
          className="animable"
        ></path>
        <g id="elchps3zmpaq6">
          <path
            d="M173 221.94a25.85 25.85 0 01-6.39 1.52 7.07 7.07 0 01-1.4 0c-8.46-1.22-19.18-8.06-41.12-31.1-7.68-8.08-9.4-12.72-8-15a3 3 0 011.07-1z"
            style={{
              WebkitTransformOrigin: 144.32341194152832,
              MsTransformOrigin: 144.32341194152832,
              transformOrigin: 144.32341194152832,
            }}
            id="elv0h8y3iq7ir"
            className="animable"
            opacity="0.1"
          ></path>
        </g>
        <path
          d="M145.15 232.8l1.49 16.2s-19.11 2.94-66.58 5c0 0 7.82-51.11 23.07-70.67 7.62-9.78 17.51-9.91 24.31-4.07 1.71 1.48 18.13 26.47 20.91 35.86 2.43 8.3-3.2 17.68-3.2 17.68z"
          style={{
            WebkitTransformOrigin: 114.50935363769531,
            MsTransformOrigin: 114.50935363769531,
            transformOrigin: 114.50935363769531,
          }}
          id="elekabnnuyi4"
          fill="#256192"
          className="animable"
        ></path>
        <path
          d="M124.52 184.74c1.78 3.91 3.49 7.85 5.09 11.83s3.21 8 4.57 12.05c.69 2 1.34 4.1 1.88 6.2a17.63 17.63 0 01.45 6.55 28.06 28.06 0 01-1.55 6.35 35.27 35.27 0 01-2.8 5.89l.07-.27c.25 2.86.41 5.73.62 8.59s.35 5.73.48 8.6q-.57-4.28-1.05-8.55c-.3-2.85-.65-5.7-.92-8.56v-.13l.08-.14a31.24 31.24 0 004.09-11.89c.62-4.19-.92-8.28-2.18-12.36s-2.79-8.09-4.28-12.11z"
          style={{
            WebkitTransformOrigin: 130.57703399658203,
            MsTransformOrigin: 130.57703399658203,
            transformOrigin: 130.57703399658203,
          }}
          id="eljzp6oorny5"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M134.43 200.47a1.07 1.07 0 01-.79 1.23 1 1 0 01-1.11-.86 1.07 1.07 0 01.79-1.23 1 1 0 011.11.86z"
          style={{
            WebkitTransformOrigin: 133.4800009727478,
            MsTransformOrigin: 133.4800009727478,
            transformOrigin: 133.4800009727478,
          }}
          id="elxmill7izp9f"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M138.72 212.23a1.07 1.07 0 01-.79 1.23 1 1 0 01-1.11-.86 1.07 1.07 0 01.79-1.23 1 1 0 011.11.86z"
          style={{
            WebkitTransformOrigin: 137.7699974179268,
            MsTransformOrigin: 137.7699974179268,
            transformOrigin: 137.7699974179268,
          }}
          id="eljesrva257x"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M139.23 224.22a1.05 1.05 0 01-.78 1.22 1 1 0 01-1.11-.86 1.06 1.06 0 01.79-1.23 1 1 0 011.1.87z"
          style={{
            WebkitTransformOrigin: 138.28510928153992,
            MsTransformOrigin: 138.28510928153992,
            transformOrigin: 138.28510928153992,
          }}
          id="ely105tfkj5x"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M135.88 234.13a1.05 1.05 0 01-.78 1.22 1 1 0 01-1.11-.86 1.06 1.06 0 01.79-1.23 1 1 0 011.1.87z"
          style={{
            WebkitTransformOrigin: 134.9351133108139,
            MsTransformOrigin: 134.9351133108139,
            transformOrigin: 134.9351133108139,
          }}
          id="elgiey875jv7r"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M137.16 245a1.07 1.07 0 01-.79 1.22 1 1 0 01-1.11-.86 1.07 1.07 0 01.79-1.23 1 1 0 011.11.87z"
          style={{
            WebkitTransformOrigin: 136.20914137363434,
            MsTransformOrigin: 136.20914137363434,
            transformOrigin: 136.20914137363434,
          }}
          id="elf4c2dafzg3"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M102.09 186.06c-9.61 18.45-26.36 56.25-19.77 66.23s41.46 15.1 53.38 16.68c3.93.52 16.79-27.85 12.85-29.39-6.7-2.62-38.16-6.93-39.25-9-.68-1.3 4.39-25.81 4.49-40.11.14-18.59-8.71-10.14-11.7-4.41z"
          style={{
            WebkitTransformOrigin: 115.05772018432617,
            MsTransformOrigin: 115.05772018432617,
            transformOrigin: 115.05772018432617,
          }}
          id="elo5h0lqaipq9"
          fill="#F7A9A0"
          className="animable"
        ></path>
        <path
          d="M145.08 238.57s12.35 1.18 20.39 2.33c4.49.64 12.08 2.43 15.16 4.4 4.61 3 22 18.28 18.36 22.57-3.28 3.89-16.54-11.17-16.54-11.17s14.72 14 9.91 18-17.52-15.05-17.52-15.05 14.8 14.73 9.19 18c-4.23 2.49-16.72-14.07-16.72-14.07s12.64 12.75 8.39 15.5c-4.69 3-17.18-11.53-17.18-11.53-12 5.16-19.77 2.73-25.12.33-1.3-.59 11.68-29.31 11.68-29.31z"
          style={{
            WebkitTransformOrigin: 166.3978042602539,
            MsTransformOrigin: 166.3978042602539,
            transformOrigin: 166.3978042602539,
          }}
          id="elr53k36w36k9"
          fill="#F7A9A0"
          className="animable"
        ></path>
        <path
          d="M177.67 251.53c1.79 1.59 3.61 3.18 5.25 4.91s3.23 3.54 4.7 5.42a40.85 40.85 0 015 7.86c-2.79-4.8-8.58-11.11-10.22-12.84s-3.19-3.55-4.83-5.29c-.01-.04.06-.09.1-.06z"
          style={{
            WebkitTransformOrigin: 185.09450721740723,
            MsTransformOrigin: 185.09450721740723,
            transformOrigin: 185.09450721740723,
          }}
          id="els5kfckt0cxa"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M171.29 257.35c2.2 2.12 4.46 4.14 6.56 6.36s3.89 4.48 5.82 6.83c.15.18 0 .2-.15 0-2-2.27-4.08-4.21-6.13-6.45s-4-4.57-6.15-6.72c-.04-.01.02-.05.05-.02z"
          style={{
            WebkitTransformOrigin: 177.47940826416016,
            MsTransformOrigin: 177.47940826416016,
            transformOrigin: 177.47940826416016,
          }}
          id="elzhxwxx3nvl"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M164.92 261.35a68.13 68.13 0 019.28 10.5c.14.19 0 .19-.2 0-3-3.55-5.37-6.35-9.19-10.38-.06-.06.04-.18.11-.12z"
          style={{
            WebkitTransformOrigin: 169.52407836914062,
            MsTransformOrigin: 169.52407836914062,
            transformOrigin: 169.52407836914062,
          }}
          id="el5xie5d3lofq"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M146.17 237.93l-23.61 31.94s-36.45-6.7-42.12-18.8 10.24-47.45 19.24-62.19 15.11-14.66 14.7 1.81c-.37 14.75-4.79 38.63-4.19 39.41s35.98 7.83 35.98 7.83z"
          style={{
            WebkitTransformOrigin: 112.70892333984375,
            MsTransformOrigin: 112.70892333984375,
            transformOrigin: 112.70892333984375,
          }}
          id="el6c305303gln"
          fill="#256192"
          className="animable"
        ></path>
        <path
          d="M136.72 237.77c-3.7 4.34-7.21 8.85-10.61 13.43-1.67 2.24-3.35 4.49-4.93 6.8s-3.16 4.91-4.83 7.29c-.07.11.09.24.16.13 1.73-2.33 3.64-4.53 5.43-6.82s3.37-4.47 5-6.71c3.42-4.58 6.82-9.17 10.07-13.86a.21.21 0 00-.29-.26z"
          style={{
            WebkitTransformOrigin: 126.67761135101318,
            MsTransformOrigin: 126.67761135101318,
            transformOrigin: 126.67761135101318,
          }}
          id="el7yqhlr1qt6j"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M146.24 237.93c-2.8-.1-36.27-7.15-36.31-7.16s1.16-10.59 1.42-12.58q.8-6.12 1.85-12.21c0-.09.17-.09.16 0-.08 2.46-.25 4.93-.47 7.37.33-1.08.68-2.15 1.09-3.2a.09.09 0 01.16.06c-.46 2.08-1 4.16-1.54 6.22-.06.55-.09 1.09-.16 1.63q-.36 3.08-.81 6.13c-.15 1-.75 4.89-.88 5.93 4.43.67 32.92 6.73 35.52 7.6.1.04.07.22-.03.21z"
          style={{
            WebkitTransformOrigin: 128.13034629821777,
            MsTransformOrigin: 128.13034629821777,
            transformOrigin: 128.13034629821777,
          }}
          id="elvisvb8ftcs"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M80.5 251.23a10.1 10.1 0 001.84 2.57c.1.09 0 .12-.15 0a4.93 4.93 0 01-1.74-2.54c-.01-.02.01-.09.05-.03z"
          style={{
            WebkitTransformOrigin: 81.41384989023209,
            MsTransformOrigin: 81.41384989023209,
            transformOrigin: 81.41384989023209,
          }}
          id="elxi3v4w4u4id"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M112.84 178.28c.25 1.25 5.89 9.47 11.55 9.39 1.85 0 2.14-10.1 2.14-10.1l.19-.74 2.67-10.34-12-8.25-1.73-1.1s-.54 2.88-1.18 6.47c0 .17-.07.33-.1.51s-.08.39-.11.58c-.1.54-.2 1.08-.29 1.63s-.15.82-.2 1.26-.13.87-.2 1.31a67.92 67.92 0 00-.74 9.38z"
          style={{
            WebkitTransformOrigin: 121.11499786376953,
            MsTransformOrigin: 121.11499786376953,
            transformOrigin: 121.11499786376953,
          }}
          id="elal0mqdqfprw"
          fill="#F7A9A0"
          className="animable"
        ></path>
        <path
          d="M114.49 163.61a21.4 21.4 0 0012.23 13.22l2.67-10.34-12-8.25-1.73-1.1s-.53 2.86-1.17 6.47z"
          style={{
            WebkitTransformOrigin: 121.93999862670898,
            MsTransformOrigin: 121.93999862670898,
            transformOrigin: 121.93999862670898,
          }}
          id="el0u7vkc2zr8bp"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M143.69 148.74c3.14 18.48-7.57 22.45-11.56 23.12-3.62.61-16 2.23-21-15.83s2.57-25.35 10.77-27.19 18.65 1.42 21.79 19.9z"
          style={{
            WebkitTransformOrigin: 126.89315986633301,
            MsTransformOrigin: 126.89315986633301,
            transformOrigin: 126.89315986633301,
          }}
          id="elkkz76sjzhsp"
          fill="#F7A9A0"
          className="animable"
        ></path>
        <path
          d="M139.66 144c-.44 0-.86 0-1.3-.06a2.16 2.16 0 01-1.32-.31.68.68 0 01-.13-.81 1.65 1.65 0 011.55-.65 2.44 2.44 0 011.66.6.72.72 0 01-.46 1.23z"
          style={{
            WebkitTransformOrigin: 138.58188438415527,
            MsTransformOrigin: 138.58188438415527,
            transformOrigin: 138.58188438415527,
          }}
          id="elj1vujebnvog"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M127.42 146.18a12.62 12.62 0 001.21-.47 2.14 2.14 0 001.15-.72.68.68 0 00-.14-.81 1.65 1.65 0 00-1.68-.12 2.44 2.44 0 00-1.37 1.11.72.72 0 00.83 1.01z"
          style={{
            WebkitTransformOrigin: 128.18722569942474,
            MsTransformOrigin: 128.18722569942474,
            transformOrigin: 128.18722569942474,
          }}
          id="elpzjk9m1u5dg"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M131 150.44c0-.05-.08.08-.07.13.28 1.25.39 2.74-.67 3.37v.07c1.37-.46 1.2-2.43.74-3.57z"
          style={{
            WebkitTransformOrigin: 130.79676795005798,
            MsTransformOrigin: 130.79676795005798,
            transformOrigin: 130.79676795005798,
          }}
          id="elw5gz1bz649"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M129.61 149.34c-2.05.23-1.43 4.33.47 4.11s1.26-4.31-.47-4.11z"
          style={{
            WebkitTransformOrigin: 129.78672587871552,
            MsTransformOrigin: 129.78672587871552,
            transformOrigin: 129.78672587871552,
          }}
          id="ele4u6beaghqf"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M128.84 149.68c-.32.28-.61.75-1 .85s-.94-.24-1.33-.61c0 0-.07 0-.06.06.11.79.53 1.55 1.39 1.54s1.23-.79 1.31-1.61c-.02-.15-.15-.35-.31-.23z"
          style={{
            WebkitTransformOrigin: 127.79950797557831,
            MsTransformOrigin: 127.79950797557831,
            transformOrigin: 127.79950797557831,
          }}
          id="eliuq3t4dfmcs"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M138 149.05c0-.05.11 0 .12.1.14 1.28.52 2.72 1.73 3v.07c-1.46-.02-1.94-1.94-1.85-3.17z"
          style={{
            WebkitTransformOrigin: 138.91975969076157,
            MsTransformOrigin: 138.91975969076157,
            transformOrigin: 138.91975969076157,
          }}
          id="el4qbuztxdg77"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M138.93 147.56c2-.45 2.77 3.63.89 4.05s-2.59-3.67-.89-4.05z"
          style={{
            WebkitTransformOrigin: 139.4310086965561,
            MsTransformOrigin: 139.4310086965561,
            transformOrigin: 139.4310086965561,
          }}
          id="elaa9uz9qarfh"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M139.83 147.67c.36.16.75.5 1.14.45s.7-.55.91-1c0-.05.06 0 .07 0 .16.78.05 1.64-.67 1.93s-1.28-.32-1.61-1.06c-.06-.14.01-.38.16-.32z"
          style={{
            WebkitTransformOrigin: 140.8313398361206,
            MsTransformOrigin: 140.8313398361206,
            transformOrigin: 140.8313398361206,
          }}
          id="el8q0zqioi72v"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M132.65 160.57c.33.27.68.65 1.15.64a3.26 3.26 0 001.34-.47l.06.06a1.73 1.73 0 01-1.55.87 1.35 1.35 0 01-1.12-1c-.03-.1.07-.14.12-.1z"
          style={{
            WebkitTransformOrigin: 133.86222553253174,
            MsTransformOrigin: 133.86222553253174,
            transformOrigin: 133.86222553253174,
          }}
          id="elmvdnhh1gol"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M137.2 155.13s.57 1.85.71 2.74c0 .08-.19.16-.49.23a4.28 4.28 0 01-4.38-.91.09.09 0 01.11-.14 6.47 6.47 0 004.06.47c0-.25-1.08-3.13-.94-3.17a8.14 8.14 0 012.1.13c-1.08-3.89-2.77-7.57-3.78-11.46a.12.12 0 01.22-.09 65.14 65.14 0 014.6 12.17c.12.49-1.83.12-2.21.03z"
          style={{
            WebkitTransformOrigin: 136.2153549194336,
            MsTransformOrigin: 136.2153549194336,
            transformOrigin: 136.2153549194336,
          }}
          id="ely9rc0pbfere"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M137.18 157.89a5.12 5.12 0 01-1.69 2 2.27 2.27 0 01-1.34.36c-1-.07-1.25-1-1.22-1.77a5.72 5.72 0 01.2-1.28 6.59 6.59 0 004.05.69z"
          style={{
            WebkitTransformOrigin: 135.05381417274475,
            MsTransformOrigin: 135.05381417274475,
            transformOrigin: 135.05381417274475,
          }}
          id="elb9yg2k66fo9"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M135.49 159.84a2.27 2.27 0 01-1.34.36c-1-.07-1.25-1-1.22-1.77a2.34 2.34 0 012.56 1.41z"
          style={{
            WebkitTransformOrigin: 134.2088282108307,
            MsTransformOrigin: 134.2088282108307,
            transformOrigin: 134.2088282108307,
          }}
          id="elbwdelhskczd"
          fill="#FF9ABB"
          className="animable"
        ></path>
        <path
          d="M111.53 157.48c3.16-.25 2.85-6.91 2.85-6.91s6.35-1 7-10.43a14.26 14.26 0 01-1.62 8s11-2.95 14.24-13c0 0-1.13 5.07-2.62 7 0 0 4.48-2.05 6.17-6.22 0 0 2.81 5.1 4.55 6.36 0 0-3.53-19.51-21.34-14.58 0 0-11.24 2.53-13.91 12.16s4.68 17.62 4.68 17.62z"
          style={{
            WebkitTransformOrigin: 124.18296241760254,
            MsTransformOrigin: 124.18296241760254,
            transformOrigin: 124.18296241760254,
          }}
          id="el1xe9z07gv4c"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M114.19 155.26s-4.56-5.5-7.09-3.83 1.24 9.87 4.44 10.78a3.17 3.17 0 004.09-2.05z"
          style={{
            WebkitTransformOrigin: 110.9502625465393,
            MsTransformOrigin: 110.9502625465393,
            transformOrigin: 110.9502625465393,
          }}
          id="eli4ig9ad1dad"
          fill="#F7A9A0"
          className="animable"
        ></path>
        <path
          d="M108.24 153.76v.08c2.25.7 3.56 2.63 4.54 4.66a1.75 1.75 0 00-2.65-.27c-.05.05 0 .13.07.12a1.93 1.93 0 012.19.5 9.64 9.64 0 011.17 1.65c.12.18.46.05.37-.17v-.05c-.21-2.78-2.68-6.49-5.69-6.52z"
          style={{
            WebkitTransformOrigin: 111.09248781204224,
            MsTransformOrigin: 111.09248781204224,
            transformOrigin: 111.09248781204224,
          }}
          id="el49co5iwz0fq"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M126.81 176.49l-2.42 8.72-11.54-9.4-2.56 3.72s12 15.64 12 14.87l2.63-7.57 5.53 4.45-1.81-12.52z"
          style={{
            WebkitTransformOrigin: 120.36999893188477,
            MsTransformOrigin: 120.36999893188477,
            transformOrigin: 120.36999893188477,
          }}
          id="elx97wjkicgw"
          fill="#256192"
          className="animable"
        ></path>
        <path
          d="M129.89 185c-.36-2.06-.68-4.14-1.14-6.18 0-.07-.13-.09-.14 0-.19 2 1.25 9.9 1.46 11.8-.23-.19-5.09-3.88-5.13-3.81-.61 1.22-2.55 6.74-2.67 7.24-1.9-2.49-9.88-12.23-12.06-14.54-.06-.06-.17 0-.12.09 1.73 2.65 12.07 15.53 12.2 15.48a81.74 81.74 0 002.74-8c.85.83 5.81 5.06 5.79 4.75a64.89 64.89 0 00-.93-6.83z"
          style={{
            WebkitTransformOrigin: 120.44870471954346,
            MsTransformOrigin: 120.44870471954346,
            transformOrigin: 120.44870471954346,
          }}
          id="elk0cff1dcf2"
          fill="#263238"
          className="animable"
        ></path>
      </g>
      <g
        id="freepik--Plant--inject-45"
        style={{
          WebkitTransformOrigin: 91.35237503051758,
          MsTransformOrigin: 91.35237503051758,
          transformOrigin: 91.35237503051758,
        }}
        className="animable"
      >
        <path
          d="M90.07 351.92a41.77 41.77 0 01-.81 16.08 44.49 44.49 0 01-2.46 7.1c-1.28 2.93-2.61 5.8-2.29 9 .31.2-.76.17-.79-.14-8.59-4.89-19.61-5.79-26.22-13.79-3.13-3.78-4.71-8.58-5.46-13.38-.81-5.23-1-11 .31-16.17 1.14-4.36 3.69-8.56 7.78-10.69s9.76-2 14.21-.64c9.6 2.99 14.37 13.34 15.73 22.63z"
          style={{
            WebkitTransformOrigin: 70.94967460632324,
            MsTransformOrigin: 70.94967460632324,
            transformOrigin: 70.94967460632324,
          }}
          id="elomznnnb17e"
          fill="#256192"
          className="animable"
        ></path>
        <path
          d="M94.48 415.51c-1.17-3.91-2.34-7.82-3.55-11.71-2.35-7.64-4.8-15.24-7.52-22.76q-2.43-6.75-5.14-13.38a80.73 80.73 0 011-8.3c.72-3.33 1.76-6.55 2.73-9.81.06-.2-.26-.3-.35-.11-1.92 4.49-4.44 10.23-4.5 15.55-2.1-5-4.34-9.93-6.76-14.78a64.25 64.25 0 01.38-12.31.15.15 0 00-.29 0 43.76 43.76 0 00-.84 10.86c-1.84-3.64-3.79-7.25-5.86-10.79-.19-.32-.63 0-.46.32q4.83 9.33 9 19a41.45 41.45 0 00-13.47-7.8c-.21-.07-.37.24-.16.33a48.85 48.85 0 0114.57 9.64q3 7.07 5.77 14.24c-5.37-5.27-12.73-8.73-19.84-10.59-.24-.07-.31.3-.07.37 8.23 2.48 14.63 7.15 20.89 12.76q1.09 2.9 2.14 5.81 4 11 7.58 22.22 1.72 5.35 3.41 10.72c1.2 3.86 2.14 7.92 3.7 11.64.15.36.79.28.71-.15a112.94 112.94 0 00-3.07-10.97z"
          style={{
            WebkitTransformOrigin: 78.07419204711914,
            MsTransformOrigin: 78.07419204711914,
            transformOrigin: 78.07419204711914,
          }}
          id="eluefde7xsha"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M80.23 344.4a21.37 21.37 0 00-3.09 7.71c0 .07.1.12.12 0a68 68 0 013.36-7.56c.13-.21-.24-.37-.39-.15z"
          style={{
            WebkitTransformOrigin: 78.89361810684204,
            MsTransformOrigin: 78.89361810684204,
            transformOrigin: 78.89361810684204,
          }}
          id="eln9d3am4qpzr"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M64.91 362.3a22.7 22.7 0 00-7.71-2.6c-.07 0-.11.1 0 .13 1.24.51 2.54.87 3.79 1.35s2.49 1.07 3.77 1.52a.22.22 0 00.15-.4z"
          style={{
            WebkitTransformOrigin: 61.06868362426758,
            MsTransformOrigin: 61.06868362426758,
            transformOrigin: 61.06868362426758,
          }}
          id="elmet4lyg1c3b"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M60.59 340.77a14.78 14.78 0 00-2.48-1.53.13.13 0 00-.14.21 14.6 14.6 0 002.44 1.55c.16.14.34-.13.18-.23z"
          style={{
            WebkitTransformOrigin: 59.294177174568176,
            MsTransformOrigin: 59.294177174568176,
            transformOrigin: 59.294177174568176,
          }}
          id="elf7q6hs7vawe"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M119 381.1c-6.19 1.83-12.4 3.53-18.59 5.35-.54.16-11-19.88-7.22-30.21a33.7 33.7 0 0121-19.95c4.42-1.5 9.85-2.76 14.52-1.73 4.15.91 7.08 4.31 8.43 8.21 1.83 5.31 1.22 11.63.22 17.05a29.74 29.74 0 01-6.09 13.91c-3.22 3.9-7.42 5.94-12.27 7.37z"
          style={{
            WebkitTransformOrigin: 115.34235191345215,
            MsTransformOrigin: 115.34235191345215,
            transformOrigin: 115.34235191345215,
          }}
          id="elfb1ebfcfawa"
          fill="#256192"
          className="animable"
        ></path>
        <path
          d="M128 362.05a82.11 82.11 0 01-12.13 4.77 50.73 50.73 0 00-8.1 2.94c2.62-5.83 5.48-11.53 8.53-17.18 4-1.16 8.2-3 11.21-5.79a.17.17 0 00-.23-.26c-3.29 2.33-6.93 3.73-10.57 5.3q1.23-2.28 2.5-4.55c.23-.43-.35-.84-.63-.44a129.21 129.21 0 00-8.79 14.77 67.17 67.17 0 01-.64-13.53c0-.12-.16-.12-.18 0a45.19 45.19 0 00.15 14.86c-2.17 4.33-4.14 8.77-5.92 13.26-.71-2.7-1.62-5.35-2.23-8.07a37 37 0 01-.89-11.91.09.09 0 00-.18 0 42 42 0 002.59 21.87c-1 2.7-2 5.41-2.92 8.13a162 162 0 00-8.21 42.93.26.26 0 00.52 0 212.77 212.77 0 019.22-42.42c1.79-5.4 3.86-10.64 6.13-15.77 3.65-1.08 7.29-2.55 10.88-3.79a34.43 34.43 0 0010-4.92.13.13 0 00-.11-.2z"
          style={{
            WebkitTransformOrigin: 109.74520492553711,
            MsTransformOrigin: 109.74520492553711,
            transformOrigin: 109.74520492553711,
          }}
          id="el8p5rximd83s"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M133.77 356.23c-3.78 1.34-7.37 3.23-11.15 4.59-.18.07-.07.35.1.31 4-.83 8-2.06 11.19-4.69.09-.1-.01-.26-.14-.21z"
          style={{
            WebkitTransformOrigin: 128.23605918884277,
            MsTransformOrigin: 128.23605918884277,
            transformOrigin: 128.23605918884277,
          }}
          id="el36cpn8awzpw"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M102.87 348.06c0-.2-.24-.31-.31-.11-1 3.49-1.75 7.19-.74 10.76a.14.14 0 00.27 0c.18-3.61-.02-7.11.78-10.65z"
          style={{
            WebkitTransformOrigin: 102.10537040233612,
            MsTransformOrigin: 102.10537040233612,
            transformOrigin: 102.10537040233612,
          }}
          id="elpoxmnkptw0q"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M118.51 340a9.17 9.17 0 00-1.64 5.93c0 .13.21.21.25.05.46-2 1.23-3.81 1.84-5.75a.26.26 0 00-.45-.23z"
          style={{
            WebkitTransformOrigin: 117.90658962726593,
            MsTransformOrigin: 117.90658962726593,
            transformOrigin: 117.90658962726593,
          }}
          id="el1gpg1p2f08j"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M45.44 389.94c1.81 5.75 6.12 13.71 13 13.77 4.42.05 8.88-.48 13.32-.54 4.74-.08 9.51-.06 14.24.22.39 0-.56-14.22-12-21.16-3.2-1.94-7.11-2.56-10.74-3.34a49.71 49.71 0 00-12.68-1.33c-2.62.11-4.81.66-5.74 3.35s-.27 6.29.6 9.03z"
          style={{
            WebkitTransformOrigin: 65.22823524475098,
            MsTransformOrigin: 65.22823524475098,
            transformOrigin: 65.22823524475098,
          }}
          id="el2wcr626wl3m"
          fill="#256192"
          className="animable"
        ></path>
        <path
          d="M50.35 382.73q4.17 1.08 8.36 2.42a30.39 30.39 0 00-2.3-2.24 45.39 45.39 0 00-3.8-2.66c-.1-.08 0-.28.1-.23a15.65 15.65 0 016.73 5.36c3.47 1.12 6.91 2.35 10.21 3.71a37.92 37.92 0 016.54 3.44 26.19 26.19 0 00-2.36-4.11c-1.52-2-3.57-3.06-5.55-4.45a.18.18 0 01.18-.31 16.22 16.22 0 018.9 9.66 38.09 38.09 0 016.48 5.88c7.55 8.57 13.5 20 13.52 31.63 0 .28-.45.36-.5.07a64.53 64.53 0 00-14.4-30.9c-.54-.63-1.1-1.23-1.66-1.81a52.08 52.08 0 00-6.24-.46c-2.43 0-4.85.36-7.27.55-4 .33-8.39.37-12-1.63-.16-.09-.05-.37.13-.3 8 2.91 16.41-.78 24.45.95A37.71 37.71 0 0068 389.62l-.74-.29c-5.14.62-10.57 2-15.58.29a.13.13 0 01.07-.24c4.84 1.21 9.48-.31 14.25-.55-5.2-2-10.5-3.64-15.71-5.65-.4-.18-.41-.57.06-.45z"
          style={{
            WebkitTransformOrigin: 73.6767406463623,
            MsTransformOrigin: 73.6767406463623,
            transformOrigin: 73.6767406463623,
          }}
          id="el215hj8u46hs"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M48.08 387.8a69.51 69.51 0 007.49.56.06.06 0 010 .11A15.48 15.48 0 0148 388c-.09 0 0-.22.08-.2z"
          style={{
            WebkitTransformOrigin: 51.784231662750244,
            MsTransformOrigin: 51.784231662750244,
            transformOrigin: 51.784231662750244,
          }}
          id="elteul3mj263"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M65 382.86a12.25 12.25 0 013.7 2.57c.13.12 0 .35-.18.25-.63-.46-1.28-.89-1.93-1.31a19.72 19.72 0 01-1.81-1.14c-.18-.14.01-.44.22-.37z"
          style={{
            WebkitTransformOrigin: 66.73039722442627,
            MsTransformOrigin: 66.73039722442627,
            transformOrigin: 66.73039722442627,
          }}
          id="eloku6vn4sczi"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M105.3 401.39c-.09.19.18.37.29.17a.3.3 0 00.18.12 116.13 116.13 0 0013.94 2.43c3.78.33 8.13.54 11.43-1.65 4.48-3 7.42-10.9 5-15.95-2.86-6-11.49-2.73-15.82-.6a31.74 31.74 0 00-15.02 15.48z"
          style={{
            WebkitTransformOrigin: 121.14586067199707,
            MsTransformOrigin: 121.14586067199707,
            transformOrigin: 121.14586067199707,
          }}
          id="elm8b02lh7ojk"
          fill="#256192"
          className="animable"
        ></path>
        <path
          d="M102 404.16a45.21 45.21 0 018.32-6.34 28.64 28.64 0 018.45-8.6c.16-.12.3.14.15.26a37.84 37.84 0 00-7.11 7.48 78 78 0 0110.44-4.75 13.92 13.92 0 015.61-5.62s.11 0 .06.08a23 23 0 00-5.06 5.31c2.12-.79 4.26-1.53 6.4-2.24.2-.06.27.23.09.3-3.82 1.49-7.72 3.07-11.5 4.88a44.22 44.22 0 0013.92 0c.13 0 .19.16.06.2a27.54 27.54 0 01-14.77.22 68.59 68.59 0 00-7 3.92 17.62 17.62 0 015.67.46 43.24 43.24 0 009.94 1.13c.13 0 .16.2 0 .22-5.77 1-11.22-1.63-16.94-.94a44.07 44.07 0 00-6 4.86 44.79 44.79 0 00-8.69 12.26c-2.41 4.9-3.71 10.1-5.66 15.16a.18.18 0 01-.35-.1c1.67-10.02 6.64-21.03 13.97-28.15z"
          style={{
            WebkitTransformOrigin: 109.96272087097168,
            MsTransformOrigin: 109.96272087097168,
            transformOrigin: 109.96272087097168,
          }}
          id="ellqjbw8dhx"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M119.35 390.47a6.55 6.55 0 011.48-1.79 5.9 5.9 0 011.71-1.28s.09.06 0 .1c-.47.53-1.13.93-1.64 1.44s-.94 1.12-1.46 1.63a.09.09 0 01-.09-.1z"
          style={{
            WebkitTransformOrigin: 120.96471929550171,
            MsTransformOrigin: 120.96471929550171,
            transformOrigin: 120.96471929550171,
          }}
          id="el9s22iw88j2v"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M128.87 393.57c.86-.22 1.76-.2 2.63-.38a25.86 25.86 0 002.56-.76c.11 0 .17.13.07.18a8.44 8.44 0 01-5.26 1.23.14.14 0 010-.27z"
          style={{
            WebkitTransformOrigin: 131.47401690483093,
            MsTransformOrigin: 131.47401690483093,
            transformOrigin: 131.47401690483093,
          }}
          id="ele1dc2yyxm5"
          fill="#263238"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 93.28499984741211,
            MsTransformOrigin: 93.28499984741211,
            transformOrigin: 93.28499984741211,
          }}
          id="elh3y9uxgzzz"
          fill="#455A64"
          d="M78.17 419.97L78.92 428.57 81.5 457.62 104.24 457.62 107.45 428.57 108.4 419.97 78.17 419.97z"
          className="animable"
        ></path>
        <path
          style={{
            WebkitTransformOrigin: 93.28499984741211,
            MsTransformOrigin: 93.28499984741211,
            transformOrigin: 93.28499984741211,
          }}
          id="elb5klo8u4wnp"
          fill="#263238"
          d="M78.17 419.97L78.92 428.57 107.45 428.57 108.4 419.97 78.17 419.97z"
          className="animable"
        ></path>
        <g id="elj6i971j3n2">
          <path
            style={{
              WebkitTransformOrigin: 93.28,
              MsTransformOrigin: 93.28,
              transformOrigin: 93.28,
            }}
            id="el7jpssjs7uun"
            fill="#455A64"
            d="M75.91 417.62H110.65V424.6H75.91z"
            className="animable"
            transform="rotate(180)"
          ></path>
        </g>
      </g>
      <g
        id="freepik--speech-bubbles-2--inject-45"
        style={{
          WebkitTransformOrigin: 337.4249725341797,
          MsTransformOrigin: 337.4249725341797,
          transformOrigin: 337.4249725341797,
        }}
        className="animable"
      >
        <path
          d="M278.31 109.5a33.37 33.37 0 0053.28 26.8l16.41 4.18-6.81-15.23a33.39 33.39 0 10-62.83-15.75z"
          style={{
            WebkitTransformOrigin: 313.15500259399414,
            MsTransformOrigin: 313.15500259399414,
            transformOrigin: 313.15500259399414,
          }}
          id="ell2afphmy20e"
          fill="#FFF"
          className="animable"
        ></path>
        <path
          d="M278.31 109.5a33.55 33.55 0 005 17.47 30.07 30.07 0 005.86 7.05 22.53 22.53 0 001.73 1.51l1.85 1.36c.62.46 1.31.81 1.95 1.21l1 .59c.33.19.69.34 1 .51l2.08 1 2.22.8a16.78 16.78 0 002.2.64c.75.16 1.47.41 2.23.51a31.8 31.8 0 004.55.49 28 28 0 004.58-.06 33 33 0 0016.82-6.58l.13-.1h.17l16.38 4.09-.51.61-6.85-15.21-.1-.22.12-.21a33.06 33.06 0 001.09-28.85A34.38 34.38 0 00333 84.26a33 33 0 00-13.21-6.85 33.17 33.17 0 00-41.44 32.09zm0 0a33.6 33.6 0 0112.82-26.36 34.24 34.24 0 0113.63-6.48 33.68 33.68 0 0140.78 34.1 33.11 33.11 0 01-4 14.72v-.43l6.77 15.25.37.83-.88-.22-16.34-4.26.3-.06A33.6 33.6 0 01314.6 143a30 30 0 01-4.64 0 32.14 32.14 0 01-4.59-.56c-.77-.11-1.5-.37-2.25-.55a17.19 17.19 0 01-2.22-.67l-2.16-.81-2.08-1c-.35-.18-.7-.33-1-.52l-1-.61c-.65-.42-1.33-.78-1.95-1.24l-1.84-1.39a21 21 0 01-1.74-1.52 38.6 38.6 0 01-3.19-3.33 36.62 36.62 0 01-2.65-3.78 33.66 33.66 0 01-4.98-17.52z"
          style={{
            WebkitTransformOrigin: 313.4949951171875,
            MsTransformOrigin: 313.4949951171875,
            transformOrigin: 313.4949951171875,
          }}
          id="el8qh4vb4x9fu"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M313.44 113.33V117a2.67 2.67 0 01-2.73 2.73A2.73 2.73 0 01308 117v-5.8-.11-.12a2.81 2.81 0 012.73-2.73 6.25 6.25 0 100-12.5 6.16 6.16 0 00-5.35 3.07 2.76 2.76 0 01-3.8 1 2.73 2.73 0 01-.95-3.74 11.72 11.72 0 1112.83 17.29zm0 12.22v1a2.67 2.67 0 01-2.73 2.74 2.74 2.74 0 01-2.73-2.74v-1a2.77 2.77 0 012.73-2.73 2.7 2.7 0 012.73 2.73z"
          style={{
            WebkitTransformOrigin: 311.3663034439087,
            MsTransformOrigin: 311.3663034439087,
            transformOrigin: 311.3663034439087,
          }}
          id="el6rs9dxall0n"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M396.52 82.7a19.31 19.31 0 01-30.83 15.5l-9.46 2.42 3.94-8.81a19.32 19.32 0 1136.35-9.11z"
          style={{
            WebkitTransformOrigin: 376.3750476837158,
            MsTransformOrigin: 376.3750476837158,
            transformOrigin: 376.3750476837158,
          }}
          id="elst8pqsi4v3b"
          fill="#256192"
          className="animable"
        ></path>
        <path
          d="M396.52 82.7a19.45 19.45 0 01-2.85 10.15 23.25 23.25 0 01-1.53 2.15 22.46 22.46 0 01-1.85 2 19 19 0 01-4.36 3.07 19.24 19.24 0 01-5.05 1.79 18.39 18.39 0 01-2.67.34 16.1 16.1 0 01-2.69 0 19.61 19.61 0 01-10-3.69l.3.06-9.44 2.5-.88.23.37-.84 3.9-8.83v.43a19.56 19.56 0 1136.77-9.34zm0 0a19.05 19.05 0 00-23.95-18.45 18.83 18.83 0 00-7.56 4 19.85 19.85 0 00-5.01 6.81 19 19 0 00-1.57 8.34 19.32 19.32 0 002.21 8.18l.12.21-.1.22-4 8.79-.51-.6 9.49-2.34h.16l.14.1a19 19 0 009.65 3.79 17.74 17.74 0 002.63.05 19.23 19.23 0 002.62-.27 18.91 18.91 0 005-1.66 17.81 17.81 0 004.38-3 22.14 22.14 0 001.85-1.9 21.72 21.72 0 001.54-2.16 19.37 19.37 0 002.91-10.11z"
          style={{
            WebkitTransformOrigin: 376.0199737548828,
            MsTransformOrigin: 376.0199737548828,
            transformOrigin: 376.0199737548828,
          }}
          id="eltiszcgi314"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M384.73 83.5a1.7 1.7 0 101.69-1.69 1.69 1.69 0 00-1.69 1.69z"
          style={{
            WebkitTransformOrigin: 386.42998361587524,
            MsTransformOrigin: 386.42998361587524,
            transformOrigin: 386.42998361587524,
          }}
          id="elzezsamj965l"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M375.8 83.5a1.7 1.7 0 101.7-1.69 1.7 1.7 0 00-1.7 1.69z"
          style={{
            WebkitTransformOrigin: 377.4999808073044,
            MsTransformOrigin: 377.4999808073044,
            transformOrigin: 377.4999808073044,
          }}
          id="elzrf07zdkgvf"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M366.88 83.5a1.7 1.7 0 101.7-1.69 1.7 1.7 0 00-1.7 1.69z"
          style={{
            WebkitTransformOrigin: 368.5799881219864,
            MsTransformOrigin: 368.5799881219864,
            transformOrigin: 368.5799881219864,
          }}
          id="el03ngx52hg8o4"
          fill="#263238"
          className="animable"
        ></path>
      </g>
      <g
        id="freepik--speech-bubbles-1--inject-45"
        style={{
          WebkitTransformOrigin: 161.42498397827148,
          MsTransformOrigin: 161.42498397827148,
          transformOrigin: 161.42498397827148,
        }}
        className="animable"
      >
        <path
          d="M103.48 102a19.31 19.31 0 0030.83 15.51l9.46 2.42-3.94-8.81a19.32 19.32 0 10-36.35-9.12z"
          style={{
            WebkitTransformOrigin: 123.62499618530273,
            MsTransformOrigin: 123.62499618530273,
            transformOrigin: 123.62499618530273,
          }}
          id="el0lo1hul5hoh"
          fill="#256192"
          className="animable"
        ></path>
        <path
          d="M103.48 102a19.34 19.34 0 002.93 10.1 21.85 21.85 0 001.54 2.17 22.14 22.14 0 001.85 1.9 17.81 17.81 0 004.38 3 18.89 18.89 0 005 1.65 17.77 17.77 0 002.62.27 15.39 15.39 0 002.63 0 18.89 18.89 0 009.65-3.78l.14-.11.16.05 9.49 2.33-.51.61-4-8.8-.1-.21.12-.21a19.32 19.32 0 002.21-8.18 18.93 18.93 0 00-1.57-8.34 19.85 19.85 0 00-5.02-6.92 18.85 18.85 0 00-7.56-4 19.17 19.17 0 00-8.59-.27A19.14 19.14 0 00103.48 102zm0 0a19.44 19.44 0 017.4-15.27 19.67 19.67 0 017.89-3.73 19.66 19.66 0 0116.7 4 19.59 19.59 0 014.78 24.39v-.42l3.9 8.83.37.84-.88-.24-9.44-2.49.3-.06a19.7 19.7 0 01-10 3.69 18.57 18.57 0 01-2.69 0 19.89 19.89 0 01-2.67-.34 19.55 19.55 0 01-9.41-4.87 17.32 17.32 0 01-3.38-4.12 19.43 19.43 0 01-2.87-10.21z"
          style={{
            WebkitTransformOrigin: 123.99996757507324,
            MsTransformOrigin: 123.99996757507324,
            transformOrigin: 123.99996757507324,
          }}
          id="el20cs2xn1jv5"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M123.49 104v2a1.46 1.46 0 01-1.49 1.49 1.5 1.5 0 01-1.5-1.49v-3.17-.06-.07a1.54 1.54 0 011.5-1.49 3.42 3.42 0 100-6.83 3.37 3.37 0 00-2.93 1.62 1.51 1.51 0 01-2.08.54 1.48 1.48 0 01-.51-2 6.41 6.41 0 117 9.46zm0 6.67v.55a1.46 1.46 0 01-1.49 1.5 1.5 1.5 0 01-1.5-1.5v-.55a1.52 1.52 0 011.5-1.49 1.47 1.47 0 011.49 1.46z"
          style={{
            WebkitTransformOrigin: 122.35834169387817,
            MsTransformOrigin: 122.35834169387817,
            transformOrigin: 122.35834169387817,
          }}
          id="el1nn1a6457eq"
          fill="#FFF"
          className="animable"
        ></path>
        <path
          d="M219.37 109.15A33.38 33.38 0 01166.09 136l-16.35 4.17 6.81-15.23a33.39 33.39 0 1162.82-15.75z"
          style={{
            WebkitTransformOrigin: 184.55501174926758,
            MsTransformOrigin: 184.55501174926758,
            transformOrigin: 184.55501174926758,
          }}
          id="elin7dvnx4mtj"
          fill="#FFF"
          className="animable"
        ></path>
        <path
          d="M219.37 109.15a33.5 33.5 0 01-5 17.52 36.62 36.62 0 01-2.65 3.78 38.6 38.6 0 01-3.19 3.33c-.59.5-1.11 1.08-1.74 1.52l-1.79 1.39c-.61.46-1.3.82-1.94 1.24l-1 .61c-.33.19-.69.34-1 .52l-2.08 1-2.17.81a15.23 15.23 0 01-2.21.67c-.75.18-1.48.44-2.25.55a32.28 32.28 0 01-4.6.56 29.82 29.82 0 01-4.63 0 33.67 33.67 0 01-17.21-6.41l.3.06-16.33 4.26-.88.23.37-.84 6.77-15.25v.43a33.11 33.11 0 01-4-14.72 33.76 33.76 0 0112.11-27.07 33.83 33.83 0 0128.68-7 34 34 0 0113.62 6.47 33.54 33.54 0 0112.82 26.34zm0 0a33.11 33.11 0 00-41.43-32.09 33 33 0 00-13.21 6.85 34.41 34.41 0 00-8.86 11.91 33 33 0 001.1 28.85l.11.21-.09.22-6.85 15.21-.51-.6 16.38-4.1h.16l.14.1a33 33 0 0016.82 6.51 29.06 29.06 0 004.57.07 33 33 0 004.55-.5c.76-.1 1.49-.35 2.24-.51a16.78 16.78 0 002.2-.64l2.16-.78 2.08-1c.34-.17.69-.32 1-.5l1-.6c.65-.4 1.34-.75 1.95-1.2l1.85-1.37a22.85 22.85 0 001.74-1.5 38.3 38.3 0 003.2-3.3 37.44 37.44 0 002.66-3.75 33.48 33.48 0 005.04-17.49z"
          style={{
            WebkitTransformOrigin: 184.18499755859375,
            MsTransformOrigin: 184.18499755859375,
            transformOrigin: 184.18499755859375,
          }}
          id="el0af8q79on5al"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M174.33 109.15a2.76 2.76 0 11-2.76-2.75 2.76 2.76 0 012.76 2.75z"
          style={{
            WebkitTransformOrigin: 171.56998825073242,
            MsTransformOrigin: 171.56998825073242,
            transformOrigin: 171.56998825073242,
          }}
          id="eloyfzh4r48lf"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M188.79 109.15a2.75 2.75 0 11-2.79-2.75 2.75 2.75 0 012.79 2.75z"
          style={{
            WebkitTransformOrigin: 186.03999781608582,
            MsTransformOrigin: 186.03999781608582,
            transformOrigin: 186.03999781608582,
          }}
          id="elnenqt9kgr8"
          fill="#263238"
          className="animable"
        ></path>
        <path
          d="M203.26 109.15a2.76 2.76 0 11-2.76-2.75 2.76 2.76 0 012.76 2.75z"
          style={{
            WebkitTransformOrigin: 200.49997758865356,
            MsTransformOrigin: 200.49997758865356,
            transformOrigin: 200.49997758865356,
          }}
          id="elzyxd4dff39"
          fill="#263238"
          className="animable"
        ></path>
      </g>
      <defs>
        <filter id="active" height="200%">
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="2"
            result="DILATED"
          ></feMorphology>
          <feFlood
            floodColor="#32DFEC"
            floodOpacity="1"
            result="PINK"
          ></feFlood>
          <feComposite
            in="PINK"
            in2="DILATED"
            operator="in"
            result="OUTLINE"
          ></feComposite>
          <feMerge>
            <feMergeNode in="OUTLINE"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <filter id="hover" height="200%">
          <feMorphology
            in="SourceAlpha"
            operator="dilate"
            radius="2"
            result="DILATED"
          ></feMorphology>
          <feFlood floodColor="red" floodOpacity="0.5" result="PINK"></feFlood>
          <feComposite
            in="PINK"
            in2="DILATED"
            operator="in"
            result="OUTLINE"
          ></feComposite>
          <feMerge>
            <feMergeNode in="OUTLINE"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
          <feColorMatrix values="0 0 0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
        </filter>
      </defs>
    </svg>
  );
}

export default AnimatedImg;
