import React from 'react'


import "./section1.scss"

const Sction1 = () => {
  return (
    <div className="parthnership-section1">
        <h1>Partner with us</h1>
        <h2>Looking for a partnership for your business. Contact us for discussing partnership opportunities</h2>
        <h2><a href="mailto:joy@docubric.com">joy@docubric.com</a></h2>
    </div>
  )
}

export default Sction1